import { Modification } from '../apis/gtoons'
import { Color } from './colors'

export function getPoints(
    points: number,
    modifications: Modification[]
): number {
    var p = points
    for (const modification of modifications) {
        const { action, value, property } = modification.modifier
        if (property === 'Points' && typeof value === 'number') {
            if (action === 'Add') p += value
            if (action === 'Mult') p += points * value - points
        }
    }
    return p
}

export function getColor(color: string, modifications: Modification[]): Color {
    var c = color
    for (const modification of modifications) {
        const { action, value, property } = modification.modifier
        if (
            property === 'Color' &&
            action === 'Set' &&
            typeof value === 'string'
        ) {
            c = value
        }
    }
    return c as Color
}
