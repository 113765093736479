import { Dispatch } from '@reduxjs/toolkit'
import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
    BoardPosition,
    ClosedEvent,
    DeckSelectStart,
    DiscardStartEvent,
    DrawEvent,
    GameOverEvent,
    InitialEvent,
    Play,
    PlayerEvent,
    PlayerPositions,
    playGtoons,
    PlayStartEvent,
    PlayType,
    ScoreEvent,
    ServerEvent,
    SilverStartEvent,
    SwapStartEvent,
} from '../apis/gtoons'
import { wait } from '../common/utils'
import { Deck } from '../deck-builder/deck'
import { gameActions, UIPhase, useGameSelector } from '../state/gameReducer'
import store from '../state/store'
import { useUser } from '../state/userReducer'
import { Color } from './colors'
import { AllDiscardPositions, Position, PositionMap } from './positions'
import { getColor } from './scoring'

export type UIEvent =
    | { type: 'ready' }
    | { type: 'yes' }
    | { type: 'no' }
    | { type: 'home' }
    | { type: 'cancel' }
    | { type: 'color'; color: Color }
    | { type: 'deck'; deck: Deck }

type EventArgs = {
    dispatch: Dispatch
    toServer: ToServer
    history: any
}

export default function useGameEngine(type: PlayType) {
    const dispatch = useDispatch()
    const history = useHistory()
    const user = useUser()
    const phase = useGameSelector((s) => s.phase)
    const positions = useGameSelector((s) => s.cardPositions)
    const play = useRef<Play | null>(null)

    const uiSend = (event: UIEvent) => {
        console.log(`Event from UI [${phase}, ${event}]`)
        handleUIEvent(
            event,
            { phase, positions },
            {
                dispatch,
                toServer: play.current?.send || ((e) => {}),
                history,
            }
        )
    }

    useEffect(() => {
        dispatch(gameActions.resetGameState())
        if (user.username === undefined || user.id === undefined) {
            console.error('Invalid user info - ', JSON.stringify(user))
            return
        }
        play.current = playGtoons(user.username, user.id, type)

        var cancelled = false

        startEventLoop(
            play.current,
            (e: any) => cancelled || dispatch(e),
            history
        )

        return function cleanup() {
            cancelled = true
            play.current?.done()
            play.current = null
        }
    }, [play, dispatch, history])

    return uiSend
}

async function startEventLoop(play: Play, dispatch: Dispatch, history: any) {
    const args: EventArgs = { dispatch, toServer: play.send, history }

    try {
        while (true) {
            if (!play) break
            const e = await play.next()
            await handleServerEvent(e, args)

            if (e.event === 'closed') {
                break
            }
        }
    } catch (err) {
        console.error(err)
    }
}

export type UIEventSender = (event: UIEvent) => void

type ToServer = (event: PlayerEvent) => void

async function handleServerEvent(event: ServerEvent, args: EventArgs) {
    switch (event.event) {
        case 'connected':
            break
        case 'deck-select-start':
            await handleDeckSelectStart(event, args)
            break
        case 'initial':
            await handleInitialEvent(event, args)
            break
        case 'draw':
            await handleDrawEvent(event, args)
            break
        case 'play-start':
            await handlePlayStartEvent(event, args)
            break
        case 'score':
            await handleScoreEvent(event, args)
            break
        case 'discard-start':
            await handleDiscardStartEvent(event, args)
            break
        case 'swap-start':
            await handleSwapStart(event, args)
            break
        case 'silver-start':
            await handleSilverStart(event, args)
            break
        case 'game-over':
            await handleGameOverEvent(event, args)
            break
        case 'closed':
            handleClosedEvent(event, args)
            break
        default:
            console.warn(`unhandled event from server - ${event.event}`)
    }
}

async function handleDeckSelectStart(event: DeckSelectStart, args: EventArgs) {
    const { dispatch } = args
    dispatch(gameActions.setPlayerIsReady(false))
    dispatch(gameActions.setPhase('DECK_SELECT'))
    dispatch(gameActions.setTimer(Date.parse(event.timestamp)))
}

async function handleInitialEvent(event: InitialEvent, args: EventArgs) {
    const { dispatch, toServer } = args
    dispatch(gameActions.setPlayerIsReady(false))
    dispatch(gameActions.setGameNames(event.names))
    dispatch(gameActions.setGameColors(event.colors))
    dispatch(gameActions.setGameDecks(event.decks))
    await wait(2000)

    // select cut cards
    dispatch(
        gameActions.setLoadingState({
            type: 'cut-cards',
            player: event.cutCards.player,
            opponent: event.cutCards.opponent,
            win: event.win,
        })
    )
    await wait(6000)

    // done
    dispatch(gameActions.setLoadingState({ type: 'loading-done' }))
    await wait(1000)

    dispatch(gameActions.setPhase('INITIAL'))
    await wait(2000)

    dispatch(gameActions.setPlayerIsReady(true))
    toServer({ event: 'ready' })
}

async function handleDrawEvent(event: DrawEvent, args: EventArgs) {
    const { dispatch, toServer } = args
    dispatch(gameActions.setPlayerIsReady(false))
    dispatch(gameActions.setPhase(event.phase))

    await wait(750)
    var drawn = 0
    for (const card of event.cards) {
        drawn++
        dispatch(
            gameActions.drawCard({
                card,
                opponentDrew: event.opponentDrawCount >= drawn,
            })
        )
        dispatch(gameActions.setGameTV(card))
        await wait(750)
    }

    dispatch(gameActions.setPlayerIsReady(true))
    toServer({ event: 'ready' })
}

async function handlePlayStartEvent(event: PlayStartEvent, args: EventArgs) {
    const { dispatch } = args
    dispatch(gameActions.setPlayerIsReady(false))
    dispatch(gameActions.setPhase(event.phase))
    dispatch(gameActions.setTimer(Date.parse(event.timestamp)))
}

async function handleScoreEvent(event: ScoreEvent, args: EventArgs) {
    const { dispatch, toServer } = args
    await wait(750)

    // place opponent cards
    var opponentPositions: Position[] = []
    if (event.phase === 'SCORING_1')
        opponentPositions = [
            'OPPONENT_ONE',
            'OPPONENT_TWO',
            'OPPONENT_THREE',
            'OPPONENT_FOUR',
        ]
    if (event.phase === 'SCORING_2')
        opponentPositions = ['OPPONENT_FIVE', 'OPPONENT_SIX', 'OPPONENT_SEVEN']
    for (const position of opponentPositions) {
        dispatch(gameActions.place({ position, status: { type: 'card-back' } }))
        await wait(750)
    }

    await wait(2000)

    dispatch(gameActions.setPhase(event.phase))
    dispatch(gameActions.setPlayerIsReady(false))

    for (const result of event.revealResults) {
        const revealed = result.position as BoardPosition

        dispatch(gameActions.flip({ position: revealed, to: result.card }))
        await wait(750)

        dispatch(gameActions.trackScore({ type: 'flip', target: revealed }))
        await wait(750)

        for (const disabled of result.disables) {
            dispatch(gameActions.disable(disabled))
            dispatch(
                gameActions.trackScore({ type: 'disabled', target: disabled })
            )
        }
        await wait(750)

        for (const modification of result.modifications) {
            dispatch(gameActions.addModification(modification))
            dispatch(
                gameActions.trackScore({
                    type: 'modification',
                    modification: modification,
                })
            )
            await wait(1000)
        }
    }

    if (event.phase === 'SCORING_3') {
        if (event.swapResult === 'opponent' || event.swapResult === 'both') {
            dispatch(
                gameActions.trackScore({
                    type: 'swap-penalty',
                    who: 'opponent',
                })
            )
        }
        if (event.swapResult === 'player' || event.swapResult === 'both') {
            dispatch(
                gameActions.trackScore({
                    type: 'swap-penalty',
                    who: 'player',
                })
            )
        }
    }

    dispatch(gameActions.setPlayerIsReady(true))
    toServer({ event: 'ready' })
}

function handleDiscardStartEvent(event: DiscardStartEvent, args: EventArgs) {
    const { dispatch } = args
    dispatch(gameActions.setPhase('DISCARD'))
    dispatch(gameActions.setDiscarding(true))
    dispatch(gameActions.setPlayerIsReady(false))
    dispatch(gameActions.setTimer(Date.parse(event.timestamp)))
}

function handleSwapStart(event: SwapStartEvent, args: EventArgs) {
    const { dispatch } = args
    dispatch(gameActions.setPlayerIsReady(false))
    dispatch(gameActions.setPhase('SWAP'))
    dispatch(gameActions.setTimer(Date.parse(event.timestamp)))
}

function handleSilverStart(event: SilverStartEvent, args: EventArgs) {
    const { dispatch, toServer } = args

    var hasSilver = event.lastCardColor === 'SILVER'

    const { game } = store.getState()
    for (const position of PlayerPositions) {
        const status = game.cardPositions[position]
        if (status.type === 'card') {
            if (
                getColor(status.card.color, status.modifications) === 'SILVER'
            ) {
                hasSilver = true
                break
            }
        }
    }

    if (hasSilver) {
        dispatch(gameActions.setPlayerIsReady(false))
        dispatch(gameActions.setPhase('SILVER'))
        dispatch(gameActions.setTimer(Date.parse(event.timestamp)))
    } else {
        toServer({ event: 'ready' })
    }
}

function handleGameOverEvent(event: GameOverEvent, args: EventArgs) {
    const { dispatch } = args

    if (event.bonus.player) {
        dispatch(
            gameActions.trackScore({
                type: 'point-bonus',
                who: 'player',
            })
        )
    } else if (event.bonus.opponent) {
        dispatch(
            gameActions.trackScore({
                type: 'point-bonus',
                who: 'opponent',
            })
        )
    }

    console.log(`FINAL POINTS: ${JSON.stringify(event.finalPoints)}`)

    if (event.reason === 'tie') {
        dispatch(gameActions.gameOver({ type: 'tie' }))
    } else if (
        event.reason === 'by_color' ||
        event.reason === 'by_points' ||
        event.reason === 'disconnect'
    ) {
        dispatch(
            gameActions.gameOver({ type: event.reason, winner: event.winner! })
        )
    } else if (event.reason === 'cancelled') {
        dispatch(gameActions.gameOver({ type: 'cancelled' }))
    }
}

function handleClosedEvent(event: ClosedEvent, args: EventArgs) {
    if (event.reason !== 'normal') {
        args.dispatch(
            gameActions.gameOver({
                type: 'error',
                error: event.reason,
            })
        )
    }
}

async function handleUIEvent(
    event: UIEvent,
    state: { phase: UIPhase; positions: PositionMap },
    args: EventArgs
) {
    if (state.phase === 'DECK_SELECT' && event.type === 'deck') {
        await handleDeckSelect(event.deck, args)
    }
    if (
        (state.phase === 'PLAY_1' || state.phase === 'PLAY_2') &&
        event.type === 'ready'
    )
        await handlePlayEvent(state.phase, state.positions, args)
    if (state.phase === 'DISCARD' && event.type === 'ready')
        await handleDiscardEvent(state.positions, args)
    if (state.phase === 'GAME_OVER' && event.type === 'home')
        await handleHomeEvent(args)
    if (state.phase === 'SWAP') await handleSwapEvent(event, args)
    if (state.phase === 'SWAP_YES')
        await handleSwapYesEvent(event, state.positions, args)
    if (state.phase === 'SILVER') await handleSilverEvent(event, args)
}

async function handleDeckSelect(deck: Deck, args: EventArgs) {
    const { dispatch, toServer } = args
    dispatch(gameActions.setPlayerIsReady(true))
    dispatch(gameActions.setPhase('LOADING'))

    await wait(500)

    toServer({ event: 'deck-select', name: deck.name, cardIds: deck.cards })
}

async function handlePlayEvent(
    phase: string,
    board: PositionMap,
    args: EventArgs
) {
    const { dispatch, toServer } = args
    dispatch(gameActions.setPlayerIsReady(true))

    var positionsToFlip: Position[] = []
    if (phase === 'PLAY_1')
        positionsToFlip = [
            'PLAYER_ONE',
            'PLAYER_TWO',
            'PLAYER_THREE',
            'PLAYER_FOUR',
        ]
    if (phase === 'PLAY_2')
        positionsToFlip = ['PLAYER_FIVE', 'PLAYER_SIX', 'PLAYER_SEVEN']

    const cardsToPlay = []

    for (const position of positionsToFlip) {
        const positionStatus = board[position]
        if (positionStatus.type === 'card') {
            cardsToPlay.push(positionStatus.card.id)
        }
    }

    // turn the cards over
    for (const position of positionsToFlip) {
        dispatch(gameActions.flip({ position, to: undefined }))
        await wait(750)
    }

    toServer({ event: 'play', cardIds: cardsToPlay })
}

async function handleDiscardEvent(board: PositionMap, args: EventArgs) {
    const { dispatch, toServer } = args

    const toDiscard = []

    for (const position of AllDiscardPositions) {
        const ps = board[position]
        if (ps.type == 'card') {
            toDiscard.push(ps.card.id)
        }
    }

    dispatch(gameActions.setPlayerIsReady(true))
    dispatch(gameActions.setDiscarding(false))

    await wait(1000)

    if (toDiscard.length > 0) {
        toServer({ event: 'discard', cardIds: toDiscard })
    } else {
        toServer({ event: 'ready' })
    }
}

async function handleHomeEvent(args: EventArgs) {
    const { history } = args

    history.push('/Home')
}

async function handleSwapEvent(event: UIEvent, args: EventArgs) {
    const { dispatch, toServer } = args
    if (event.type === 'no') {
        dispatch(gameActions.setPlayerIsReady(true))
        toServer({ event: 'ready' })
    }
    if (event.type === 'yes') {
        dispatch(gameActions.setPhase('SWAP_YES'))
    }
}

async function handleSwapYesEvent(
    event: UIEvent,
    board: PositionMap,
    args: EventArgs
) {
    const { dispatch, toServer } = args

    const seven = board['PLAYER_SEVEN']

    if (event.type === 'ready') {
        if (seven.type === 'card') {
            dispatch(
                gameActions.flip({ position: 'PLAYER_SEVEN', to: undefined })
            )
            await wait(750)
            dispatch(gameActions.setPlayerIsReady(true))
            toServer({ event: 'swap', cardId: seven.card.id })
        }
    }
    if (event.type === 'cancel') {
        if (seven.type === 'card') {
            dispatch(
                gameActions.place({
                    position: 'PLAYER_SEVEN',
                    status: { type: 'open' },
                })
            )
            await wait(750)
            dispatch(
                gameActions.place({
                    position: 'PLAYER_SEVEN',
                    status: { type: 'card-back' },
                })
            )
        }
        dispatch(gameActions.setPlayerIsReady(true))
        toServer({ event: 'ready' })
    }
}

async function handleSilverEvent(event: UIEvent, args: EventArgs) {
    const { dispatch, toServer } = args

    if (event.type === 'color') {
        dispatch(gameActions.setPlayerIsReady(true))
        toServer({ event: 'silver', color: event.color })
    }
}
