export const gradients = {
    BLACK: buildGradient('BLACK', '#585858', '#000000'),
    BLUE: buildGradient('BLUE', '#9899F5', '#0000E8'),
    GREEN: buildGradient('GREEN', '#A2FAA2', '#00C200'),
    ORANGE: buildGradient('ORANGE', '#F0A062', '#F06800'),
    PURPLE: buildGradient('PURPLE', '#B07AE6', '#7300E6'),
    RED: buildGradient('RED', '#D68D8D', '#F50000'),
    SILVER: buildGradient('SILVER', '#EAEAEA', '#ACACAC'),
    YELLOW: buildGradient('YELLOW', '#F7F499', '#FFEA00'),
}

function buildGradient(id, from, to) {
    return (
        <linearGradient key={id} id={id} gradientTransform="rotate(45)">
            <stop offset="0%" stopColor={from} />
            <stop offset="100%" stopColor={to} />
        </linearGradient>
    )
}
