import Animal from './animal.svg'
import Female from './female.svg'
import Hero from './hero.svg'
import Male from './male.svg'
import Monster from './monster.svg'
import Place from './place.svg'
import Prop from './prop.svg'
import Vehicle from './vehicle.svg'
import Villain from './villain.svg'

const types = {
    Animal,
    Female,
    Hero,
    Male,
    Monster,
    Place,
    Prop,
    Vehicle,
    Villain,
}

export default types
