import { animated, config, useSpring } from '@react-spring/web'
import { FunctionComponent, useEffect } from 'react'
import { BoardPosition, Modification } from '../apis/gtoons'
import { useGameSelector } from '../state/gameReducer'
import { CARD_SIZE, GAME_HEIGHT, GAME_WIDTH } from './constants'
import { positionToCoordinates } from './coordinates'
import { FONT } from './styling'

const ModificationAnimations: FunctionComponent = () => {
    const latest = useGameSelector((s) => s.latestModification)
    if (!latest) return <></>

    const source = positionToCoordinates(latest.source)
    const target = positionToCoordinates(latest.target)
    const labelProps = toLabelProps(latest)

    return (
        <svg
            width={GAME_WIDTH}
            height={GAME_HEIGHT}
            x={0}
            y={0}
            style={{ pointerEvents: 'none' }}
        >
            <Source
                x={source.x + CARD_SIZE / 2}
                y={source.y + CARD_SIZE / 2}
                size={CARD_SIZE}
            />
            {labelProps && (
                <Label
                    x={target.x + CARD_SIZE / 2}
                    y={target.y + 20}
                    {...labelProps}
                />
            )}
        </svg>
    )
}

type SourceProps = {
    x: number
    y: number
    size: number
}

const Source: FunctionComponent<SourceProps> = ({ x, y, size }) => {
    const [style, _] = useSpring(
        {
            reset: true,
            from: { opacity: 0 },
            to: [{ opacitiy: 1 }, { opacity: 0 }],
        },
        [x, y]
    )
    return (
        <>
            <animated.circle
                cx={x}
                cy={y}
                r={size / 2}
                style={style}
                stroke="white"
                strokeWidth="8"
                fillOpacity="0"
            />
        </>
    )
}

type LabelProps = {
    text: string
    fill: string
    x: number
    y: number
}

const Label: FunctionComponent<LabelProps> = ({ text, fill, x, y }) => {
    const [style, _] = useSpring(
        {
            reset: true,
            from: {
                fontSize: 0,
                transform: 'translateY(50px)',
                opacity: 1,
            },
            to: [
                { fontSize: 44, transform: 'translateY(0px)', opacity: 1 },
                { fontSize: 44, transform: 'translateY(0px)', opacity: 0 },
            ],
            config: config.wobbly,
        },
        [text, x, y]
    )

    return (
        <animated.text
            x={x}
            y={y}
            fill={fill}
            fontFamily={FONT}
            fontWeight="bold"
            dominantBaseline="middle"
            fontSize="44"
            fontStyle="italic"
            textAnchor="middle"
            stroke="black"
            strokeWidth="1.5"
            style={style}
        >
            {text}
        </animated.text>
    )
}

function toLabelProps(
    modification: Modification
): { text: string; fill: string } | undefined {
    if (
        modification.modifier.property !== 'Points' ||
        typeof modification.modifier.value !== 'number'
    )
        return undefined

    return {
        text: `${modification.modifier.value > 0 ? '+' : ''}${
            modification.modifier.value
        }`,
        fill: modification.modifier.value < 0 ? 'red' : 'green',
    }
}

export default ModificationAnimations
