import { useTransition } from '@react-spring/core'
import { animated } from '@react-spring/web'
import { FunctionComponent, SVGAttributes } from 'react'
import { selectDiscardPositions, useGameSelector } from '../state/gameReducer'
import { DISCARD_SIZE } from './constants'
import { AnimatedPosition } from './Position'

type DiscardDrawerProps = {
    state: 'open' | 'closed'
} & SVGAttributes<SVGSVGElement>

const DiscardDrawer: FunctionComponent<DiscardDrawerProps> = ({ x, y }) => {
    const discardPositions = useGameSelector(selectDiscardPositions)
    const phase = useGameSelector((s) => s.phase)
    const discarding = useGameSelector((s) => s.discarding)

    const transitions = useTransition(discarding, {
        from: { transform: 'translateY(-100px)' },
        enter: { transform: 'translateY(0px)' },
        leave: { transform: 'translateY(-100px)' },
    })

    return transitions(
        (style, item) =>
            item && (
                <animated.g style={style}>
                    <svg x={x} y={y} width={142} height={188}>
                        <path
                            fill="url('#drawer-background')"
                            d="M142 0 0 0 0 139.8058C0 142.8904 2.0191 145.6114 4.9713 146.5054L132.9713 185.2659C136.6713 186.3864 140.5791 184.2952 141.6996 180.5951C141.8988 179.9373 142 179.2537 142 178.5664L142 0 142 0Z"
                        />
                        <path
                            fill="#83A2BB"
                            transform="translate(10, 0)"
                            d="M124 0 0 0 0 135.0422C0 136.7999 1.1474 138.3516 2.828 138.8666L118.828 174.415C120.9402 175.0623 123.1772 173.8748 123.8244 171.7626C123.9408 171.3828 124 170.9878 124 170.5906L124 0 124 0Z"
                        />
                    </svg>

                    {discardPositions.map((p) => (
                        <AnimatedPosition
                            key={p.position}
                            status={p.status}
                            position={p.position}
                            size={DISCARD_SIZE}
                        />
                    ))}
                </animated.g>
            )
    )
}

export default DiscardDrawer
