export function times(n: number) {
    const arr = []
    for (var i = 0; i < n; i++) {
        arr.push(i)
    }
    return arr
}

export function shuffle<T>(arr: T[]): T[] {
    for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1))
        ;[arr[i], arr[j]] = [arr[j], arr[i]]
    }
    return arr
}

export function randomInt(n: number): number {
    return Math.floor(Math.random() * n)
}

export function random<T>(arr: T[]): T {
    return arr[randomInt(arr.length)]
}

export function wait(ms: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve()
        }, ms)
        // resolve()
    })
}
