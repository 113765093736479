import { FunctionComponent, useState } from 'react'
import { useGameSelector } from '../state/gameReducer'
import styles from './Game.module.css'
import { FONT } from './styling'
import { UIEvent } from './useGameEngine'

type ResultsProps = {
    cx?: number
    cy?: number
    sendUIEvent: (e: UIEvent) => void
}

const Results: FunctionComponent<ResultsProps> = ({
    cx = 510,
    cy = 372,
    sendUIEvent,
}) => {
    const results = useGameSelector((s) => s.results)
    const [highlighted, setHighlighted] = useState<'done' | undefined>()

    if (results === undefined) return <></>

    const w = 350
    const h = 200

    var header = ''
    var subtitle = ''

    const textOptions = {
        textAnchor: 'middle',
        dominantBaseline: 'hanging',
        fontFamily: FONT,
        fontWeight: 'bold',
    }

    if (results.type === 'tie') {
        header = 'Tie Game!'
    } else if (results.type === 'cancelled') {
        header = 'Game cancelled'
        subtitle = 'Your opponent left.'
    } else if (results.type === 'error') {
        if (results.error === 'game_not_found') {
            header = 'Game not found.'
            subtitle = 'Try creating a new game!'
        } else if (results.error == 'game_already_started') {
            header = 'Game already started.'
            subtitle = 'Try creating a new game!'
        } else {
            header = 'Uh oh!'
            subtitle = 'Something went wrong!  Try creating a new game!'
        }
    } else if (results.winner === 'player') {
        header = 'Congratulations!'
        if (results.type === 'by_points') subtitle = 'Winner by Points'
        if (results.type === 'by_color') subtitle = 'Winner by Color'
        if (results.type === 'disconnect') subtitle = 'Your opponent left'
    } else if (results.winner === 'opponent') {
        subtitle = 'Better luck next time.'
        if (results.type === 'by_points') header = 'Game lost by Points.'
        if (results.type === 'by_color') header = 'Game lost by Color.'
    }

    const stroke = 6

    const bw = 160
    const bh = 32

    return (
        <svg
            className={styles['shadow']}
            x={cx - w / 2}
            y={cy - h / 2}
            width={w}
            height={h}
        >
            <rect
                x={0}
                y={0}
                width={w}
                height={h}
                fill="#e8e8e8"
                rx="8"
                ry="8"
            />
            <rect
                x={stroke}
                y={stroke}
                width={w - stroke * 2}
                height={h - stroke * 2}
                fill="url(#panel-background)"
                rx="8"
                ry="8"
            />
            <text
                x={w / 2}
                y={24}
                fill="white"
                fontStyle="italic"
                fontSize="32px"
                {...textOptions}
            >
                {header}
            </text>
            <text
                x={w / 2}
                y={64}
                fill="white"
                opacity="60%"
                fontSize="24px"
                {...textOptions}
            >
                {subtitle}
            </text>

            <svg
                onClick={() => sendUIEvent({ type: 'home' })}
                x={w / 2 - bw / 2}
                y={120}
                style={{ cursor: 'pointer' }}
                onMouseOver={() => setHighlighted('done')}
                onMouseLeave={() => setHighlighted(undefined)}
            >
                <rect width={bw} height={bh} rx="8" ry="8" fill="#83A2BB" />
                <text
                    x={bw / 2}
                    y={bh / 2}
                    {...textOptions}
                    dominantBaseline="middle"
                    fill="white"
                    opacity={highlighted === 'done' ? '80%' : '100%'}
                >
                    Done
                </text>
            </svg>
        </svg>
    )
}

export default Results
