import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import {
    AllBoardPositions,
    AllHandPositions,
    BoardPosition,
    Card,
    HandPosition,
    isPlayer,
    Modification,
    Phase,
    Players,
} from '../apis/gtoons'
import colors, { Color } from '../game/colors'
import {
    AllDiscardPositions,
    DiscardPosition,
    InitialPositions,
    Position,
    PositionMap,
    PositionStatus,
    UIPosition,
} from '../game/positions'
import { getColor, getPoints } from '../game/scoring'
import { RootState } from './store'

export type ColorScore = { color: Color; score: number }[]
export type DeckState = { name: string; remaining: number }

type Who = 'player' | 'opponent'

export type LoadingState = LoadingData | CutCards | LoadingDone
type LoadingData = { type: 'loading-data' }
type CutCards = {
    type: 'cut-cards'
    player: Card
    opponent: Card
    win: 'none' | 'one' | 'two'
}
type LoadingDone = { type: 'loading-done' }

type TrackScoreEvent =
    | {
          type: 'points'
          who: Who
          action: 'add' | 'remove'
          value: number
          why?: 'swap-penalty' | 'point-bonus'
      }
    | {
          type: 'color'
          who: Who
          action: 'add' | 'remove'
          value: keyof typeof colors
      }
type TrackScoreAction =
    | {
          type: 'flip'
          target: BoardPosition
      }
    | {
          type: 'modification'
          modification: Modification
      }
    | {
          type: 'disabled'
          target: BoardPosition
      }
    | { type: 'swap-penalty'; who: Who }
    | { type: 'point-bonus'; who: Who }

export type UIPhase = 'LOADING' | Phase | 'SWAP_YES'

export type GameState = {
    loading: LoadingState
    colors?: Color[]
    info: {
        names: Players<string | undefined>
        decks: Players<DeckState | undefined>
    }
    latestModification?: Modification
    scores: TrackScoreEvent[]
    cardPositions: PositionMap
    discarding: boolean
    phase: UIPhase
    playerIsReady: boolean
    tv: Card | undefined
    timer: number | undefined
    held: { card: Card; originalPosition: Position } | undefined
    results?: Results
}

export type Results =
    | { type: 'by_points'; winner: 'player' | 'opponent' }
    | { type: 'by_color'; winner: 'player' | 'opponent' }
    | { type: 'disconnect'; winner: 'player' | 'opponent' }
    | { type: 'cancelled' }
    | { type: 'tie' }
    | {
          type: 'error'
          error: 'unexpected_error' | 'game_not_found' | 'game_already_started'
      }

const empty = () => ({ player: undefined, opponent: undefined })

const initialState = () =>
    ({
        phase: 'CONNECTING',
        loading: { type: 'loading-data' },
        colors: undefined,
        info: {
            names: empty(),
            decks: empty(),
        },
        scores: [],
        cardPositions: { ...InitialPositions() },
        discarding: false,
        playerIsReady: false,
        tv: undefined,
        timer: undefined,
        held: undefined,
        results: undefined,
    } as GameState)

const slice = createSlice({
    name: 'game',
    initialState: { ...initialState() },
    reducers: {
        resetGameState() {
            return initialState()
        },
        setLoadingState(state, action: PayloadAction<LoadingState>) {
            state.loading = action.payload
        },
        setGameColors(state, action: PayloadAction<Color[]>) {
            state.colors = action.payload
        },
        setGameNames(state, action: PayloadAction<Players<string>>) {
            state.info.names = action.payload
        },
        setGameDecks(state, action: PayloadAction<Players<DeckState>>) {
            state.info.decks = action.payload
        },
        setGameTV(state, action: PayloadAction<Card | undefined>) {
            state.tv = action.payload
        },
        setPhase(state, action: PayloadAction<UIPhase>) {
            state.phase = action.payload
            if (state.phase === 'PLAY_1') {
                state.cardPositions['PLAYER_ONE'] = { type: 'open' }
                state.cardPositions['PLAYER_TWO'] = { type: 'open' }
                state.cardPositions['PLAYER_THREE'] = { type: 'open' }
                state.cardPositions['PLAYER_FOUR'] = { type: 'open' }
            } else if (state.phase === 'PLAY_2') {
                state.cardPositions['PLAYER_FIVE'] = { type: 'open' }
                state.cardPositions['PLAYER_SIX'] = { type: 'open' }
                state.cardPositions['PLAYER_SEVEN'] = { type: 'open' }
            }
        },
        setDiscarding(state, action: PayloadAction<boolean>) {
            state.discarding = action.payload
        },
        setTimer(state, action: PayloadAction<number | undefined>) {
            state.timer = action.payload
        },
        setPlayerIsReady(state, action: PayloadAction<boolean>) {
            state.playerIsReady = action.payload
        },
        drawCard(
            state,
            action: PayloadAction<{ card: Card; opponentDrew: boolean }>
        ) {
            for (const position of AllHandPositions) {
                if (state.cardPositions[position].type === 'open') {
                    state.cardPositions[position] = {
                        type: 'card',
                        card: action.payload.card,
                        disabled: false,
                        modifications: [],
                    }

                    if (state.info.decks.player) {
                        state.info.decks.player.remaining -= 1
                    }

                    if (
                        state.info.decks.opponent &&
                        action.payload.opponentDrew
                    ) {
                        state.info.decks.opponent.remaining -= 1
                    }
                    break
                }
            }
        },
        flip(
            state,
            action: PayloadAction<{ position: Position; to: Card | undefined }>
        ) {
            if (action.payload.to === undefined) {
                state.cardPositions[action.payload.position] = {
                    type: 'card-back',
                }
            } else {
                state.cardPositions[action.payload.position] = {
                    type: 'card',
                    card: action.payload.to,
                    disabled: false,
                    modifications: [],
                }
                state.tv = action.payload.to
            }
        },
        place(
            state,
            action: PayloadAction<{
                position: Position
                status: PositionStatus
            }>
        ) {
            state.cardPositions[action.payload.position] = action.payload.status
        },
        addModification(state, action: PayloadAction<Modification>) {
            const positionStatus = state.cardPositions[action.payload.target]
            if (positionStatus.type === 'card') {
                positionStatus.modifications.push(action.payload)
                state.latestModification = action.payload
            }
        },
        trackScore(state, action: PayloadAction<TrackScoreAction>) {
            const events = getScoreTrackerEvents(state, action.payload)
            state.scores.push(...events)
        },
        disable(state, action: PayloadAction<Position>) {
            const positionStatus = state.cardPositions[action.payload]
            if (positionStatus.type === 'card') {
                positionStatus.disabled = true
            }
        },
        hold(state, action: PayloadAction<UIPosition>) {
            const positionStatus = state.cardPositions[action.payload]
            if (
                state.playerIsReady ||
                state.held ||
                positionStatus.type !== 'card'
            )
                return
            if (
                state.phase !== 'PLAY_1' &&
                state.phase !== 'PLAY_2' &&
                state.phase !== 'DISCARD' &&
                state.phase !== 'SWAP_YES'
            )
                return

            const availableHoldPositions = activePositionsForPhase(state.phase)
            console.log(
                `HOLD - [${action.payload}], Available Positions - ${availableHoldPositions}`
            )
            if (!availableHoldPositions.includes(action.payload)) return

            const card = positionStatus.card
            state.held = { card, originalPosition: action.payload }
            state.cardPositions[action.payload] = { type: 'open' }
        },
        drop(state, action: PayloadAction<Position | undefined>) {
            if (!state.held) return
            const status: PositionStatus = {
                type: 'card',
                card: state.held.card,
                disabled: false,
                modifications: [],
            }

            const availableDropPositions = activePositionsForPhase(state.phase)

            if (
                action.payload === undefined ||
                !availableDropPositions.includes(action.payload)
            ) {
                state.cardPositions[state.held.originalPosition] = status
            } else {
                const dropStatus = state.cardPositions[action.payload]
                if (dropStatus.type === 'open') {
                    state.cardPositions[action.payload] = status
                } else if (dropStatus.type === 'card') {
                    // swap places
                    state.cardPositions[action.payload] = status
                    state.cardPositions[state.held.originalPosition] =
                        dropStatus
                } else if (
                    dropStatus.type === 'card-back' &&
                    state.phase === 'SWAP_YES'
                ) {
                    // swap with last card
                    state.cardPositions[action.payload] = status
                } else {
                    state.cardPositions[state.held.originalPosition] = status
                }
            }
            state.held = undefined
        },
        gameOver(state, action: PayloadAction<Results>) {
            state.phase = 'GAME_OVER'
            state.results = action.payload
        },
    },
})

export default slice.reducer

export const gameActions = slice.actions

export function useGame() {
    return useSelector((state: RootState) => state.game)
}

export function useGameSelector<T>(f: (state: GameState) => T): T {
    return useSelector((state: RootState) => f(state.game))
}

function activePositionsForPhase(phase: UIPhase): UIPosition[] {
    const positions: { [p in UIPhase]: UIPosition[] } = {
        CONNECTING: [],
        DECK_SELECT: [],
        LOADING: [],
        INITIAL: [],
        DRAW_1: [],
        PLAY_1: [
            'PLAYER_ONE',
            'PLAYER_TWO',
            'PLAYER_THREE',
            'PLAYER_FOUR',
            ...AllHandPositions,
        ] as Position[],
        SCORING_1: [],
        DISCARD: [
            'DISCARD_ONE',
            'DISCARD_TWO',
            ...AllHandPositions,
        ] as UIPosition[],
        DRAW_2: [],
        PLAY_2: [
            'PLAYER_FIVE',
            'PLAYER_SIX',
            'PLAYER_SEVEN',
            ...AllHandPositions,
        ] as Position[],
        SCORING_2: [],
        SWAP: [],
        SWAP_YES: ['PLAYER_SEVEN', ...AllHandPositions],
        SILVER: [],
        SCORING_3: [],
        GAME_OVER: [],
    }

    return positions[phase]
}

export function selectHandPositions(
    state: GameState
): { position: HandPosition; status: PositionStatus }[] {
    return AllHandPositions.map((position) => ({
        position,
        status: state.cardPositions[position],
    }))
}

export function selectBoardPositions(
    state: GameState
): { position: BoardPosition; status: PositionStatus }[] {
    return AllBoardPositions.map((position) => ({
        position,
        status: state.cardPositions[position],
    }))
}

export function selectDiscardPositions(
    state: GameState
): { position: DiscardPosition; status: PositionStatus }[] {
    return AllDiscardPositions.map((position) => ({
        position,
        status: state.cardPositions[position],
    }))
}

export type StatusAction =
    | 'none'
    | 'ready'
    | 'done'
    | 'yes-no'
    | 'ready-cancel'
    | 'cancel'
    | 'colors'

export function selectActionStatus(state: GameState): StatusAction {
    if (state.playerIsReady) return 'none'

    if (state.phase === 'PLAY_1') {
        return state.cardPositions['PLAYER_ONE'].type === 'card' &&
            state.cardPositions['PLAYER_TWO'].type === 'card' &&
            state.cardPositions['PLAYER_THREE'].type === 'card' &&
            state.cardPositions['PLAYER_FOUR'].type === 'card'
            ? 'ready'
            : 'none'
    }

    if (state.phase == 'PLAY_2') {
        return state.cardPositions['PLAYER_FIVE'].type === 'card' &&
            state.cardPositions['PLAYER_SIX'].type === 'card' &&
            state.cardPositions['PLAYER_SEVEN'].type === 'card'
            ? 'ready'
            : 'none'
    }

    const statuses: {
        [p in UIPhase]: StatusAction
    } = {
        CONNECTING: 'none',
        DECK_SELECT: 'none',
        LOADING: 'none',
        INITIAL: 'none',
        DRAW_1: 'none',
        PLAY_1:
            state.cardPositions['PLAYER_ONE'].type === 'card' &&
            state.cardPositions['PLAYER_TWO'].type === 'card' &&
            state.cardPositions['PLAYER_THREE'].type === 'card' &&
            state.cardPositions['PLAYER_FOUR'].type === 'card'
                ? 'ready'
                : 'none',
        SCORING_1: 'none',
        DISCARD: 'ready',
        DRAW_2: 'none',
        PLAY_2:
            state.cardPositions['PLAYER_FIVE'].type === 'card' &&
            state.cardPositions['PLAYER_SIX'].type === 'card' &&
            state.cardPositions['PLAYER_SEVEN'].type === 'card'
                ? 'ready'
                : 'none',
        SCORING_2: 'none',
        SWAP: 'yes-no',
        SWAP_YES:
            state.cardPositions['PLAYER_SEVEN'].type === 'card'
                ? 'ready-cancel'
                : 'cancel',
        SILVER: 'colors',
        SCORING_3: 'none',
        GAME_OVER: 'done',
    }

    return statuses[state.phase]
}

function getScoreTrackerEvents(
    state: GameState,
    action: TrackScoreAction
): TrackScoreEvent[] {
    if (action.type === 'flip') {
        const target = action.target
        const position = state.cardPositions[target]
        if (position.type !== 'card') {
            return []
        }

        return [
            {
                type: 'points',
                action: 'add',
                value: position.card.points,
                who: isPlayer(target) ? 'player' : 'opponent',
            },
            {
                type: 'color',
                action: 'add',
                value: position.card.color,
                who: isPlayer(target) ? 'player' : 'opponent',
            },
        ]
    }

    if (action.type === 'disabled') {
        const target = action.target
        const position = state.cardPositions[target]
        if (position.type !== 'card') {
            return []
        }

        return [
            {
                type: 'color',
                action: 'remove',
                value: getColor(position.card.color, position.modifications),
                who: isPlayer(target) ? 'player' : 'opponent',
            },
            {
                type: 'points',
                action: 'remove',
                value: getPoints(position.card.points, position.modifications),
                who: isPlayer(target) ? 'player' : 'opponent',
            },
        ]
    }

    if (action.type === 'point-bonus') {
        return [
            {
                type: 'points',
                action: 'add',
                value: 15,
                who: action.who,
                why: 'point-bonus',
            },
        ]
    }

    if (action.type === 'swap-penalty') {
        return [
            {
                type: 'points',
                action: 'remove',
                value: 10,
                who: action.who,
                why: 'swap-penalty',
            },
        ]
    }

    if (action.type === 'modification') {
        const modification = action.modification
        const position = state.cardPositions[modification.target]
        if (position.type !== 'card') {
            return []
        }

        if (
            modification.modifier.property === 'Points' &&
            typeof modification.modifier.value === 'number'
        ) {
            if (modification.modifier.action === 'Add') {
                return [
                    {
                        type: 'points',
                        action: 'add',
                        value: modification.modifier.value,
                        who: isPlayer(modification.target)
                            ? 'player'
                            : 'opponent',
                    },
                ]
            }
            if (modification.modifier.action === 'Mult') {
                return [
                    {
                        type: 'points',
                        action: 'add',
                        value:
                            position.card.points * modification.modifier.value -
                            position.card.points,
                        who: isPlayer(modification.target)
                            ? 'player'
                            : 'opponent',
                    },
                ]
            }
            if (modification.modifier.action === 'Div') {
                return [
                    {
                        type: 'points',
                        action: 'remove',
                        value:
                            position.card.points * modification.modifier.value -
                            position.card.points,
                        who: isPlayer(modification.target)
                            ? 'player'
                            : 'opponent',
                    },
                ]
            }
        }

        if (
            modification.modifier.property === 'Color' &&
            typeof modification.modifier.value === 'string' &&
            typeof modification.current === 'string' &&
            typeof modification.previous === 'string'
        ) {
            if (modification.current !== modification.previous) {
                return [
                    {
                        type: 'color',
                        action: 'remove',
                        value: modification.previous,
                        who: isPlayer(modification.target)
                            ? 'player'
                            : 'opponent',
                    },
                    {
                        type: 'color',
                        action: 'add',
                        value: modification.current,
                        who: isPlayer(modification.target)
                            ? 'player'
                            : 'opponent',
                    },
                ]
            }
        }
    }
    return []
}
