import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { usernameUpdated, useUserSelector } from '../state/userReducer'
import Logo from '../common/Logo'
import styles from './Home.module.css'
import { FunctionComponent, useState } from 'react'
import { newGame } from '../apis/gtoons'
import { useLocation } from 'react-use'

function Home() {
    const dispatch = useDispatch()
    const username = useUserSelector((s) => s.username)

    return (
        <>
            <Logo />
            <div className={styles.wrapper}>
                <h1>
                    Welcome <strong>{username}</strong>!
                </h1>
                <div className={styles.menu}>
                    <Versus />
                    <Link className={styles.button} to="/Play/Computer">
                        Play Computer
                    </Link>
                    <Link className={styles.button} to="/DeckBuilder">
                        Deck Builder
                    </Link>
                    <Link
                        className={styles.button}
                        to="/Setup"
                        onClick={() => dispatch(usernameUpdated(undefined))}
                    >
                        Change Username
                    </Link>
                </div>
            </div>
        </>
    )
}

const Versus: FunctionComponent = () => {
    const [state, setState] = useState<string>('init')
    const history = useHistory()
    const location = useLocation()

    var button: any
    var link: any
    var click: (() => void) | undefined
    var linkClick: (() => void) | undefined
    switch (state) {
        case 'init':
            button = 'New Game'
            link = ''
            click = () => {
                setState('loading')
                newGame()
                    .then((id) => setState(id))
                    .catch((_) => setState('init'))
            }
            linkClick = undefined
            break
        case 'loading':
            button = 'Loading'
            link = ''
            click = undefined
            linkClick = undefined
            break
        default:
            button = 'Play'
            link = `Click to copy game link`
            click = () => history.push(`/Play/${state}`)
            linkClick = () =>
                navigator.clipboard.writeText(
                    `${location.origin}/Play/${state}`
                )
            break
    }

    return (
        <>
            <div className={styles.versus}>
                <div
                    className={styles['versus-button']}
                    style={{ cursor: !!click ? 'pointer' : 'default' }}
                    onClick={click}
                >
                    {button}
                </div>
                <div
                    className={styles['versus-link']}
                    style={{ cursor: !!linkClick ? 'pointer' : 'default' }}
                    onClick={linkClick}
                >
                    {link}
                </div>
            </div>
        </>
    )
}

export default Home
