import nouns from '../resources/data/nouns.json'
import adjs1 from '../resources/data/adjs-1.json'
import adjs2 from '../resources/data/adjs-2.json'
import { random } from '../common/utils'

export interface UsernameOptions {
    firstNames: string[]
    secondNames: string[]
    thirdNames: string[]
}

function randomUsername(options: UsernameOptions) {
    return () =>
        [
            random(options.firstNames),
            random(options.secondNames),
            random(options.thirdNames),
        ] as [string, string, string]
}

const emptyOptions: UsernameOptions = {
    firstNames: [],
    secondNames: [],
    thirdNames: [],
}

const defaultOptions: UsernameOptions = {
    firstNames: adjs1,
    secondNames: adjs2,
    thirdNames: nouns,
}

export function useUsername(): [
    UsernameOptions,
    () => [string, string, string]
] {
    const options = { ...defaultOptions }

    return [options, randomUsername(options)]
}
