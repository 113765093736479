import { SVGAttributes } from 'react'
import { FunctionComponent } from 'react'
import { times } from '../common/utils'
import { DeckState } from '../state/gameReducer'
import { FONT } from './styling'

type DeckProps = {
    state?: DeckState
    shuffling?: boolean
} & SVGAttributes<SVGElement>

const Deck: FunctionComponent<DeckProps> = ({ state, ...props }) => {
    if (!state) return <></>

    const textOptions = {
        fontSize: '16px',
        fontFamily: FONT,
        fontStyle: 'italic',
        fontWeight: 'bold',
        fill: '#E8E8E8',
    }

    return (
        <svg {...props} width={170} height={40}>
            <text x={4} y={4} dominantBaseline="hanging" {...textOptions}>
                {state.name}
            </text>
            <text x={166} y={36} textAnchor="end" {...textOptions}>
                {state.remaining}
            </text>
            {times(state.remaining).map((i) => (
                <rect
                    key={i}
                    fill="#e8e8e8"
                    width={8}
                    height={22}
                    x={26 + 10 * i}
                    y={9}
                    rx={3}
                />
            ))}
        </svg>
    )
}

export default Deck
