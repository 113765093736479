import { FunctionComponent, CSSProperties, SVGAttributes } from 'react'
import { Card as CardDTO, Modification } from '../apis/gtoons'
import { getColor, getPoints } from '../game/scoring'
import { FONT } from '../game/styling'
import { gradients } from './Gradient'

const textDivStyle = {
    height: '100%',
    fontSize: '72px',
    fontFamily: FONT,
    textAlign: 'center',
    fontStyle: 'italic',
    fontWeight: 'bold',
    fill: '#FFFFFF',
    display: 'flex',
    alignItems: 'center',
} as CSSProperties

type CardProps = {
    modifications?: Modification[]
    disabled?: boolean
    card: CardDTO
} & SVGAttributes<SVGElement>

const Card: FunctionComponent<CardProps> = ({
    className,
    modifications = [],
    disabled = false,
    card,
    ...props
}) => {
    const points = getPoints(card.points, modifications)
    const color = getColor(card.color, modifications)

    return (
        <svg className={className} viewBox="0 0 372 372" {...props}>
            <defs>{(gradients as any)[color] || <></>}</defs>

            <circle cx="186" cy="186" r="186" fill="#E3EEF4" strokeWidth="0" />
            <circle cx="186" cy="186" r="172" fill="#B2D2E4" strokeWidth="0" />
            <circle
                cx="186"
                cy="186"
                r="162"
                fill={`url('#${color}')`}
                strokeWidth="0"
            />

            <foreignObject
                x="36"
                y="36"
                width="300"
                height="300"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
            >
                <img
                    alt={card.name}
                    width="300"
                    height="300"
                    srcSet={Object.keys(card.images)
                        .map((k) => `${card.images[k]} ${k}`)
                        .join(', ')}
                    draggable="false"
                    style={{
                        MozUserSelect: 'none',
                        WebkitUserSelect: 'none',
                        userSelect: 'none',
                        pointerEvents: 'none',
                    }}
                />
            </foreignObject>

            <circle cx="251" cy="271" r="65" fill="#B2D2E4" strokeWidth="0" />
            <circle
                cx="251"
                cy="271"
                r="54"
                fill={`url('#${color}')`}
                strokeWidth="0"
            />

            <foreignObject
                x="197"
                y="217"
                width="108"
                height="108"
                requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility"
            >
                <div style={textDivStyle}>
                    <div
                        style={{
                            flex: '0 0 108px',
                            color: 'white',
                            userSelect: 'none',
                        }}
                    >
                        {points}
                    </div>
                </div>
            </foreignObject>
            {disabled ? (
                <circle
                    cx="186"
                    cy="186"
                    r="186"
                    fill="#000000"
                    opacity="0.3"
                    strokeWidth="0"
                />
            ) : (
                <></>
            )}
        </svg>
    )
}

export default Card
