import './App.css'

import { Switch, Route, Redirect, useParams } from 'react-router-dom'
import Home from './home/Home'
import Setup from './setup/Setup'
import DeckBuilder from './deck-builder/DeckBuilder'
import Game from './game/Game'
import { useUserSelector, validUser } from './state/userReducer'
import { FunctionComponent } from 'react'
import { computer, pvp } from './apis/gtoons'

function App() {
    const isUserValid = useUserSelector(validUser)

    return (
        <div className="App">
            <Routes needsSetup={!isUserValid} />
        </div>
    )
}

type RouteProps = {
    needsSetup?: boolean
}

const Routes: FunctionComponent<RouteProps> = ({ needsSetup }) => {
    if (needsSetup) {
        return (
            <Switch>
                <Route exact path="/Setup" component={Setup} />
                <Route path="*">
                    <Redirect to="/Setup" />
                </Route>
            </Switch>
        )
    }

    return (
        <Switch>
            <Route exact path="/Home" component={Home} />
            <Route exact path="/DeckBuilder" component={DeckBuilder} />
            <Route exact path="/Play/Computer" component={PlayComputer} />
            <Route exact path="/Play/:id" component={PlayPVP} />
            <Route path="*">
                <Redirect to="/Home" />
            </Route>
        </Switch>
    )
}

const PlayComputer: FunctionComponent = () => <Game type={computer} />
const PlayPVP: FunctionComponent = () => {
    const { id } = useParams<{ id: string }>()

    return <Game type={pvp(id)} />
}

export default App
