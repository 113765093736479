import { FunctionComponent, SVGAttributes, useState } from 'react'
import { Deck, isComplete } from '../deck-builder/deck'
import DeckComponent from './Deck'
import { useGame, useGameSelector } from '../state/gameReducer'
import { useUser } from '../state/userReducer'
import { GAME_HEIGHT, GAME_WIDTH } from './constants'
import NamePlate from './NamePlate'
import { times } from '../common/utils'
import { FONT } from './styling'
import { UIEvent, UIEventSender } from './useGameEngine'
import { useTransition } from '@react-spring/core'
import { animated } from '@react-spring/web'
import StatusBar from './StatusBar'

type DeckSelectProps = {
    sendUIEvent: UIEventSender
}

const DeckSelect: FunctionComponent<DeckSelectProps> = ({ sendUIEvent }) => {
    const phase = useGameSelector((s) => s.phase)
    const { username, decks } = useUser()
    const [detail, setDetail] = useState<Deck>(decks[0])

    if (phase !== 'DECK_SELECT' && phase != 'CONNECTING') return <></>

    return (
        <svg width={GAME_WIDTH} height={GAME_HEIGHT} x={0} y={0}>
            <AnimatedDeckSelectStatusBar
                cx={GAME_WIDTH / 2}
                cy={200}
                sendUIEvent={sendUIEvent}
            />
            <DeckList
                username={username}
                decks={phase === 'CONNECTING' ? [] : decks}
                x={250}
                y={230}
                onHover={(d) => setDetail(d)}
                selectDeck={(d) => sendUIEvent({ type: 'deck', deck: d })}
            />
            <DeckDetail
                x={484}
                y={230}
                deck={phase === 'CONNECTING' ? undefined : detail}
            />
        </svg>
    )
}

type DeckListProps = {
    username?: string
    decks: Deck[]
    onHover?: (d: Deck) => void
    selectDeck?: (d: Deck) => void
} & SVGAttributes<SVGSVGElement>

const DeckList: FunctionComponent<DeckListProps> = ({
    x,
    y,
    decks,
    username,
    onHover,
    selectDeck,
}) => {
    return (
        <svg x={x} y={y} width={200} height={270}>
            <rect
                width={200}
                height={270}
                fill="url(#drawer-background)"
                rx={8}
                ry={8}
            />
            <rect width="200" height="40" x={0} y={14} fill="#7099b5" />
            <rect width="200" height="40" x={0} y={64} fill="#7099b5" />
            <rect width="200" height="40" x={0} y={114} fill="#7099b5" />
            <rect width="200" height="40" x={0} y={164} fill="#7099b5" />

            {decks.slice(0, 4).map((d, i) => (
                <g
                    style={{
                        cursor: isComplete(d) ? 'pointer' : 'default',
                    }}
                    onMouseOver={() => onHover && onHover(d)}
                    onMouseDown={() =>
                        selectDeck && isComplete(d) && selectDeck(d)
                    }
                >
                    <rect
                        width="200"
                        height="40"
                        x={0}
                        y={14 + 50 * i}
                        fill="#7099b5"
                    />
                    <DeckComponent
                        x={10}
                        y={14 + 50 * i}
                        state={{ name: d.name, remaining: d.cards.length }}
                        fillOpacity={isComplete(d) ? 1 : 0.5}
                    />
                </g>
            ))}

            <NamePlate x={8} y={220} username={username} />
        </svg>
    )
}

type DeckDetailProps = {
    deck?: Deck
} & SVGAttributes<SVGSVGElement>

const DeckDetail: FunctionComponent<DeckDetailProps> = ({ x, y, deck }) => {
    const textOptions: SVGAttributes<SVGTextElement> = {
        fontSize: '12px',
        fontFamily: FONT,
        fontWeight: 'bold',
        fill: '#A2F5F5',
        dominantBaseline: 'middle',
    }

    return (
        <svg x={x} y={y} width={266} height={402}>
            <path
                fill="url(#drawer-background)"
                d="M16 0 250 0C258.8366-0 266 7.1634 266 16L266 391.2787C266 395.697 262.4183 399.2787 258 399.2787C257.2288 399.2787 256.4617 399.1672 255.7224 398.9476L5.7224 324.6995C2.3279 323.6914 0 320.5716 0 317.0306L0 16C0 7.1634 7.1634 0 16 0Z"
            />
            <rect
                x={14}
                y={14}
                width={238}
                height={296}
                fill="url(#panel-background)"
                rx={8}
                ry={8}
            />
            {times(12).map((i) => (
                <g>
                    <rect
                        x={14}
                        y={36 + i * 22}
                        width={238}
                        height={2}
                        fill="#A2F5F5"
                    />
                    {deck?.cards[i] && (
                        <text
                            x={18}
                            y={50 + i * 22}
                            width={238}
                            height={20}
                            {...textOptions}
                        >
                            {deck.cards[i]}
                        </text>
                    )}
                </g>
            ))}
        </svg>
    )
}

const AnimatedDeckSelectStatusBar: FunctionComponent<LoadingStatusBarProps> = (
    props
) => {
    const transitions = useTransition(true, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    })

    return transitions(
        (style, item) =>
            item && (
                <animated.g style={style}>
                    <LoadingStatusBar {...props} />
                </animated.g>
            )
    )
}

type LoadingStatusBarProps = {
    cx?: number
    cy?: number
    sendUIEvent: (e: UIEvent) => void
}

const LoadingStatusBar: FunctionComponent<LoadingStatusBarProps> = ({
    cx = 0,
    cy = 0,
    sendUIEvent,
}) => {
    const ready = useGameSelector((s) => s.playerIsReady)
    const phase = useGameSelector((s) => s.phase)
    const timer = useGameSelector((s) => s.timer)

    var message, width, showTimer
    switch (phase) {
        case 'DECK_SELECT':
            message = 'Choose Your Deck'
            width = 170
            showTimer = true
            break
        case 'CONNECTING':
            message = 'Connecting...'
            width = 140
            showTimer = false
            break
        default:
            message = ''
            width = 0
            showTimer = false
            break
    }

    if (ready) {
        message = 'Waiting for Opponent...'
        width = 210
        showTimer = false
    }

    return (
        <StatusBar
            cx={cx}
            cy={cy}
            width={width}
            action={'none'}
            message={message}
            sendUIEvent={sendUIEvent}
            timer={showTimer ? timer : undefined}
        />
    )
}

export default DeckSelect
