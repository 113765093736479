import watch from 'redux-watch'
import { createSlice, PayloadAction, Store } from '@reduxjs/toolkit'
import { Deck, EMPTY_DECKS, validDeck } from '../deck-builder/deck'
import { useSelector } from 'react-redux'
import { RootState } from './store'
import { v4 as uuidv4 } from 'uuid'

type UserState = {
    id?: string
    username?: string
    decks: Deck[]
}

const defaultValue = {
    decks: EMPTY_DECKS,
}

const storedValue = window.localStorage.getItem('userInfo')
const initial: UserState = storedValue ? JSON.parse(storedValue) : defaultValue

const slice = createSlice({
    name: 'user',
    initialState: initial,
    reducers: {
        usernameUpdated(state, action: PayloadAction<string | undefined>) {
            state.username = action.payload
            if (state.id === undefined) {
                state.id = uuidv4()
            }
        },
        deckUpdated(state, action: PayloadAction<Deck>) {
            if (!validDeck(action.payload)) return

            state.decks = state.decks.map((d) =>
                d.name === action.payload.name ? action.payload : d
            )
        },
    },
})

export function useUser() {
    return useSelector((state: RootState) => state.user)
}

export function useUserSelector<T>(f: (state: UserState) => T): T {
    return useSelector((state: RootState) => f(state.user))
}

export function validUser(state: UserState): boolean {
    return state.username !== undefined && state.id !== undefined
}

export default slice.reducer

export function watchUser(store: Store) {
    const w = watch(store.getState, 'user')
    store.subscribe(
        w((newValue, oldValue, path) => {
            console.log(
                `[${path}] changed from ${JSON.stringify(
                    oldValue
                )} to ${JSON.stringify(newValue)}`
            )
            window.localStorage.setItem('userInfo', JSON.stringify(newValue))
        })
    )
}

export const { usernameUpdated, deckUpdated } = slice.actions
