import { FunctionComponent } from 'react'
import { gradients } from '../common/Gradient'

const SVGDefs: FunctionComponent = () => (
    <defs>
        <linearGradient id="drawer-background">
            <stop offset="0%" stopColor="#A9C7D7" />
            <stop offset="50%" stopColor="#D1E7F3" />
            <stop offset="100%" stopColor="#A9C7D7" />
        </linearGradient>
        <linearGradient id="panel-background" gradientTransform="rotate(90)">
            <stop offset="0%" stopColor="#006CAA" />
            <stop offset="100%" stopColor="#003A71" />
        </linearGradient>
        {Object.values(gradients)}
    </defs>
)

export default SVGDefs
