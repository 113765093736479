import { FunctionComponent } from 'react'
import { PlayType } from '../apis/gtoons'
import Cursor from './Cursor'
import DeckSelect from './DeckSelect'
import styles from './Game.module.css'
import GameBoard from './GameBoard'
import GameDrawerLeft from './GameDrawerLeft'
import GameDrawerRight from './GameDrawerRight'
import Loading from './Loading'
import Results from './Results'
import SVGDefs from './SVGDefs'

import useGameEngine from './useGameEngine'

type GameProps = {
    type: PlayType
}

const Game: FunctionComponent<GameProps> = ({ type }) => {
    const sendUIEvent = useGameEngine(type) as any

    return (
        <div className={styles['game']}>
            <svg
                className={styles['game-svg']}
                version="1.1"
                baseProfile="full"
                viewBox="0 0 1024 768"
            >
                <SVGDefs />
                <Cursor>
                    <GameBoard sendUIEvent={sendUIEvent} />
                    <GameDrawerLeft />
                    <GameDrawerRight />
                </Cursor>
                <Results sendUIEvent={sendUIEvent} />
                <DeckSelect sendUIEvent={sendUIEvent} />
                <Loading />
            </svg>
        </div>
    )
}

export default Game
