import { random } from '../common/utils'

const colors = {
    BLACK: { shortDisplay: 'Blk' },
    BLUE: { shortDisplay: 'Blue' },
    GREEN: { shortDisplay: 'Grn' },
    ORANGE: { shortDisplay: 'Orng' },
    PURPLE: { shortDisplay: 'Prp' },
    RED: { shortDisplay: 'Red' },
    SILVER: { shortDisplay: 'Slv' },
    YELLOW: { shortDisplay: 'Ylw' },
}

export default colors
export type Color = keyof typeof colors
export const AllColors = Object.keys(colors) as Color[]

export function randomColor(): string {
    return random(Object.keys(colors))
}
