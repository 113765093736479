import { FunctionComponent } from 'react'
import { SVGAttributes } from 'react'
import { gradients } from '../common/Gradient'
import { useGameSelector } from '../state/gameReducer'
import styles from './Game.module.css'
import Panel from './Panel'
import colorMap, { Color } from './colors'
import NamePlate from './NamePlate'
import Deck from './Deck'
import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/web'
import { FONT } from './styling'

const GameDrawerLeft: FunctionComponent = () => {
    const info = useGameSelector((s) => s.info)
    const phase = useGameSelector((s) => s.phase)

    if (
        phase === 'CONNECTING' ||
        phase === 'LOADING' ||
        phase === 'DECK_SELECT'
    )
        return <></>

    return (
        <g className={styles['slide-left']}>
            <svg width={170} height={646} x={20} y={52}>
                <path
                    fill="url('#drawer-background')"
                    d="M8.97516427,2.6397542 L164.257331,48.3109796 C167.661993,49.3123507 170,52.4370352 170,55.9859036 L170,590.762334 C170,593.867594 167.954243,596.601693 164.975164,597.477893 L8.97516427,643.360246 C5.26626372,644.451099 1.37529459,642.328752 0.284441489,638.619852 C0.0957869184,637.978426 3.51240075e-13,637.313281 3.41060513e-13,636.644687 L3.41060513e-13,9.35531271 C3.46804314e-13,5.48931946 3.13400675,2.35531271 7,2.35531271 C7.6685935,2.35531271 8.33373873,2.45109963 8.97516427,2.6397542 Z"
                />

                <rect width="170" height="40" x={0} y={82} fill="#7099b5" />
                <rect width="170" height="40" x={0} y={524} fill="#7099b5" />

                <Panel x={10} y={127} label="Color" />
                <Panel x={10} y={220} label="Points" />
                <VS x={0} y={313} />
                <Panel x={10} y={338} label="Points" />
                <Panel x={10} y={431} label="Color" />

                {/* Player */}
                <NamePlate x={8} y={572} username={info.names.player} />
                <PointsScore x={10} y={344} who="player" />
                <ColorScore x={10} y={437} who="player" />
                <Deck x={0} y={524} state={info.decks.player} />

                {/* Opponent */}
                <NamePlate
                    x={8}
                    y={34}
                    username={info.names.opponent}
                    alignItems="flex-end"
                />
                <PointsScore x={10} y={226} who="opponent" />
                <ColorScore x={10} y={133} who="opponent" />
                <Deck x={0} y={82} state={info.decks.opponent} />
            </svg>
        </g>
    )
}

const VS: FunctionComponent<{ x: number; y: number }> = ({ x, y }) => {
    const textOptions = {
        fontSize: '16px',
        fontFamily: 'Impact',
        fontWeight: 'bold',
        fill: '#7099B5',
    }

    return (
        <svg width={170} height={20} x={x} y={y}>
            <rect fill="#7099b5" x={0} y={4} width={70} height={4} />
            <rect fill="#7099b5" x={0} y={10} width={70} height={4} />
            <text
                x={85}
                y={10}
                textAnchor="middle"
                dominantBaseline="middle"
                {...textOptions}
            >
                VS
            </text>
            <rect fill="#7099b5" x={100} y={4} width={70} height={4} />
            <rect fill="#7099b5" x={100} y={10} width={70} height={4} />
        </svg>
    )
}

type ScoreProps = {
    who: 'player' | 'opponent'
} & SVGAttributes<SVGElement>

const scoreTextOptions = {
    fontFamily: FONT,
    fontStyle: 'italic',
    fontWeight: 'bold',
    fill: '#9DEDED',
    dominantBaseline: 'hanging',
}

const ColorScore: FunctionComponent<ScoreProps> = ({ x, y, who }) => {
    var view = <></>

    const w = 150
    const h = 82
    const colors = useGameSelector((s) => s.colors)
    const scores = useGameSelector((s) => s.scores)
    var pointBonus = false

    const scoreMap = new Map<Color, number>()
    for (const event of scores) {
        if (
            event.type === 'points' &&
            event.who === who &&
            event.why === 'point-bonus'
        ) {
            pointBonus = true
        }
        if (
            typeof event.value !== 'string' ||
            event.type !== 'color' ||
            event.who !== who
        )
            continue

        if (event.action === 'add') {
            const score = scoreMap.get(event.value)
            scoreMap.set(event.value, (score || 0) + 1)
        }
        if (event.action === 'remove') {
            const score = scoreMap.get(event.value)
            scoreMap.set(event.value, (score || 0) - 1)
        }
    }

    const getScore = (color: Color) => scoreMap.get(color) || 0

    if (colors?.length === 0) {
        view = (
            <text
                x={75}
                y={46}
                {...scoreTextOptions}
                fontSize={16}
                textAnchor="middle"
                dominantBaseline="middle"
            >
                No Color Win
            </text>
        )
    } else if (colors?.length === 1) {
        view = (
            <>
                {gradients[colors[0]]}
                <g transform="translate(0, -5)">
                    <text
                        x={56}
                        y={25}
                        textAnchor="middle"
                        fontSize={12}
                        {...scoreTextOptions}
                    >
                        {colorMap[colors[0]].shortDisplay}
                    </text>
                    <circle
                        r={9}
                        cx={26 + 30}
                        cy={49}
                        fill={`url('#${colors[0]}')`}
                    />
                    <text
                        x={41 + 35}
                        y={28}
                        fontSize={38}
                        {...scoreTextOptions}
                    >
                        {getScore(colors[0])}
                    </text>
                </g>
                <text
                    x={75}
                    y={61}
                    {...scoreTextOptions}
                    fill={pointBonus ? '#9DEDED' : '#83A2BB'}
                    fontSize={12}
                    textAnchor="middle"
                >
                    +15 Color Bonus
                </text>
            </>
        )
    } else if (colors?.length === 2) {
        // Two
        view = (
            <>
                {gradients[colors[0]]}
                <text
                    x={26}
                    y={25}
                    textAnchor="middle"
                    fontSize={12}
                    {...scoreTextOptions}
                >
                    {colorMap[colors[0]].shortDisplay}
                </text>
                <circle r={9} cx={26} cy={49} fill={`url('#${colors[0]}')`} />
                <text x={41} y={28} fontSize={38} {...scoreTextOptions}>
                    {getScore(colors[0])}
                </text>

                {gradients[colors[1]]}
                <text
                    x={26 + 66}
                    y={25}
                    textAnchor="middle"
                    fontSize={12}
                    {...scoreTextOptions}
                >
                    {colorMap[colors[1]].shortDisplay}
                </text>
                <circle
                    r={9}
                    cx={26 + 66}
                    cy={49}
                    fill={`url('#${colors[1]}')`}
                />
                <text x={41 + 66} y={28} fontSize={38} {...scoreTextOptions}>
                    {getScore(colors[1])}
                </text>
            </>
        )
    }

    return (
        <svg x={x} y={y} width={w} height={h}>
            {view}
        </svg>
    )
}

const PointsScore: FunctionComponent<ScoreProps> = ({ x, y, who }) => {
    const scores = useGameSelector((s) => s.scores)

    var score = 0
    var didSwap = false

    for (const event of scores) {
        if (
            typeof event.value !== 'number' ||
            event.type !== 'points' ||
            event.who !== who
        )
            continue

        if (event.action === 'add') {
            score += event.value
        }

        if (event.action === 'remove') {
            score -= event.value
        }

        if (event.why === 'swap-penalty') {
            didSwap = true
        }
    }

    const [style, _] = useSpring(
        {
            from: { fontSize: 38 },
            to: [{ fontSize: 46 }, { fontSize: 38 }],
        },
        [score]
    )

    return (
        <svg x={x} y={y} width={150} height={82}>
            <animated.text
                x={75}
                y={36}
                {...scoreTextOptions}
                fontSize={38}
                textAnchor="middle"
                dominantBaseline="central"
                style={style}
            >
                {score}
            </animated.text>
            <text
                x={75}
                y={61}
                {...scoreTextOptions}
                fill={didSwap ? '#9DEDED' : '#83A2BB'}
                fontSize={12}
                textAnchor="middle"
            >
                -10 for Swapping
            </text>
        </svg>
    )
}

export default GameDrawerLeft
