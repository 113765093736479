import Identicon from 'identicon.js'
import md5 from 'md5'
import { FunctionComponent, memo } from 'react'

type UserIconProps = {
    size: number
    name: string
}

const UserIcon: FunctionComponent<UserIconProps> = memo(({ size, name }) => {
    const data = new Identicon(md5(name), size).toString()
    return (
        <img
            alt={name}
            src={`data:image/png;base64,${data}`}
            width={size}
            height={size}
        />
    )
})

export default UserIcon
