import { auto } from '@popperjs/core'
import { FunctionComponent, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Card as CardDTO, getCards } from '../apis/gtoons'
import { useApi } from '../apis/useApi'
import Card from '../common/Card'
import Logo from '../common/Logo'
import { deckUpdated, useUser } from '../state/userReducer'
import styles from './DeckBuilder.module.css'

import Types from '../resources/images/types'
import { Deck } from './deck'
import { gradients } from '../common/Gradient'

type Selection = string | null

const DeckBuilder: FunctionComponent = () => {
    const dispatch = useDispatch()

    const { decks } = useUser()
    const [loading, cards, error] = useApi(getCards)
    const [selected, setSelected] = useState<string | null>(null)
    const [tv, setTV] = useState<CardDTO | null>(null)

    const selectedCards = decks.find((d) => d.name === selected)?.cards
    console.log(selectedCards)

    return (
        <>
            <Background>
                <div className={styles['left']}>
                    <Link to="/Home">
                        <Logo type="small" />
                    </Link>
                    <DeckList
                        cards={cards || []}
                        selected={
                            decks.find((d) => d.name === selected) || undefined
                        }
                        selectDeck={(d) => setSelected(d?.name || null)}
                    />
                </div>

                <div className={styles['center']}>
                    <CardList
                        cards={cards || []}
                        title={selected ? selected : 'Collection'}
                        onHover={setTV}
                        disabled={selectedCards}
                        onSelect={
                            selected
                                ? (c) => {
                                      const updatedDeck = {
                                          name: selected,
                                          cards: [
                                              c.id,
                                              ...(selectedCards || []),
                                          ],
                                      }
                                      dispatch(deckUpdated(updatedDeck))
                                  }
                                : undefined
                        }
                    />
                </div>
                <div className={styles['right']}>
                    <TV card={tv || undefined} />
                </div>
            </Background>
        </>
    )
}

const Background: FunctionComponent = ({ children }) => {
    return <div className={styles['screen']}>{children}</div>
}

type DeckListProps = {
    selected?: Deck
    selectDeck: (d: Deck | null) => void
    cards: CardDTO[]
}

const DeckList: FunctionComponent<DeckListProps> = ({
    cards,
    selected,
    selectDeck,
}) => {
    const { decks } = useUser()
    const dispatch = useDispatch()

    return (
        <div style={{ alignSelf: 'stretch' }}>
            {decks.map((d) => (
                <>
                    <div
                        className={styles.deck}
                        key={d.name}
                        onClick={() => {
                            selectDeck(d.name === selected?.name ? null : d)
                        }}
                    >
                        <div>{d.name}</div>
                        <div>
                            {Array(12)
                                .fill(0)
                                .map((_, i) => (
                                    <DeckCountMarker
                                        key={i}
                                        filled={d.cards[i] !== undefined}
                                    />
                                ))}
                        </div>
                    </div>
                    {d.name === selected?.name &&
                        Array(12)
                            .fill(0)
                            .map((_, i) => (
                                <DeckCardRow
                                    key={i}
                                    onClick={(c) => {
                                        const newDeck = {
                                            ...selected,
                                            cards: selected.cards.filter(
                                                (x) => x !== c.id
                                            ),
                                        }
                                        dispatch(deckUpdated(newDeck))
                                    }}
                                    card={cards.find((c) => {
                                        const sorted = [...d.cards].sort()
                                        return c.id === sorted[i]
                                    })}
                                />
                            ))}
                </>
            ))}
        </div>
    )
}

type DeckCardRowProps = {
    card?: CardDTO
    onClick?: (c: CardDTO) => void
}

const DeckCardRow: FunctionComponent<DeckCardRowProps> = ({
    card,
    onClick,
}) => {
    if (!card)
        return (
            <div
                className={`${styles['deck-card-row']} ${styles['deck-card-row-empty']}`}
            >
                Empty
            </div>
        )

    return (
        <div
            className={styles['deck-card-row']}
            onClick={() => onClick && onClick(card)}
        >
            <svg
                width="10px"
                height="10px"
                viewBox="0 0 10 10"
                version="1.1"
                style={{ verticalAlign: 'top', paddingRight: '4px' }}
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs>{gradients[card.color]}</defs>
                <circle cx={5} cy={5} r={5} fill={`url(#${card.color})`} />
            </svg>
            <div>{card.name}</div>
            <div style={{ flex: '1', textAlign: 'end' }}>{card.points}</div>
        </div>
    )
}

const DeckCountMarker: FunctionComponent<{ filled: boolean }> = ({
    filled,
}) => (
    <svg
        width="10px"
        height="22px"
        viewBox="0 0 10 22"
        version="1.1"
        style={{ verticalAlign: 'top' }}
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="1"
            y="0"
            width="8"
            height="22"
            rx="3"
            fill={filled ? '#E3EEF4 ' : 'rgba(232, 232, 232, 0.7)'}
        />
    </svg>
)

type CardFilter = {
    search: string
}

type CardListProps = {
    cards: CardDTO[]
    title?: string
    disabled?: string[]
    onHover?: (card: CardDTO) => void
    onSelect?: (card: CardDTO) => void
}

const CardList: FunctionComponent<CardListProps> = ({
    title,
    cards,
    disabled,
    onHover,
    onSelect,
}) => {
    const [filter, setFilter] = useState<CardFilter>({ search: '' })

    const setSearch = (s: string) => {
        setFilter({ ...filter, search: s })
    }

    return (
        <>
            <div className={styles.header}>
                {title && <div className={styles.title}>{title}</div>}
                <Filter searchChanged={setSearch} />
            </div>

            <div className={styles['card-list']}>
                {withFilter(cards, filter).map((c) => (
                    <Card
                        className={styles['card']}
                        key={c.id}
                        card={c}
                        disabled={disabled?.includes(c.id)}
                        onMouseOver={() => onHover && onHover(c)}
                        onClick={() =>
                            !disabled?.includes(c.id) && onSelect && onSelect(c)
                        }
                        style={{
                            cursor:
                                !onSelect || disabled?.includes(c.id)
                                    ? 'default'
                                    : 'pointer',
                        }}
                    />
                ))}
            </div>
        </>
    )
}

function withFilter(cards: CardDTO[], filter: CardFilter): CardDTO[] {
    const { search } = filter

    return cards.filter((c) => {
        return (
            c.character.toLowerCase().includes(search.toLowerCase()) ||
            c.color.toLowerCase().includes(search.toLowerCase()) ||
            c.group?.toLowerCase().includes(search.toLowerCase()) ||
            c.name.toLowerCase().includes(search.toLowerCase()) ||
            c.types.some((t) =>
                t.toLowerCase().includes(search.toLowerCase())
            ) ||
            `${c.points}`.includes(search.toLowerCase())
        )
    })
}

type TVProps = {
    card?: CardDTO
}

const TV: FunctionComponent<TVProps> = ({ card }) => {
    if (!card) return <div className={styles.tv}></div>
    return (
        <div className={styles.tv}>
            {card && <Card card={card} width={160} height={160} />}
            <div className={styles['card-types']}>
                {card.types.map((type) => (
                    <img
                        alt={type}
                        key={type}
                        title={type}
                        src={(Types as any)[type]}
                    />
                ))}
            </div>
            <div
                style={{
                    fontSize: '24px',
                    color: '#A2F5F5',
                }}
            >
                {card.name}
            </div>

            {card.group && (
                <div style={{ paddingTop: '8px', color: '#D1E7F3' }}>
                    {card.group}
                </div>
            )}
            <div style={{ paddingTop: '8px', color: 'white' }}>
                {card.description}
            </div>
        </div>
    )
}

type FilterProps = {
    searchChanged?: (s: string) => void
}

const Filter: FunctionComponent<FilterProps> = ({ searchChanged }) => {
    return (
        <div className={styles.filter}>
            <input
                type="search"
                className={styles.search}
                size={40}
                onChange={(s) => searchChanged && searchChanged(s.target.value)}
            />
        </div>
    )
}

export default DeckBuilder
