import { CSSProperties, FunctionComponent, SVGAttributes } from 'react'
import { animated, config, useTransition, Transition } from 'react-spring'
import { LoadingState, useGameSelector } from '../state/gameReducer'
import Deck from './Deck'
import NamePlate from './NamePlate'
import StatusBar from './StatusBar'
import { FONT } from './styling'
import TV from './TV'
import { UIEvent } from './useGameEngine'

const Loading: FunctionComponent = () => {
    const phase = useGameSelector((s) => s.phase)
    const loading = useGameSelector((s) => s.loading)
    const info = useGameSelector((s) => s.info)

    if (phase !== 'LOADING') return <></>
    return (
        <svg width={1024} height={768} x={0} y={0}>
            <AnimatedLoadingDrawer state={loading} x={193} y={155} side="left">
                <TV
                    x={12}
                    y={12}
                    card={
                        loading.type === 'cut-cards'
                            ? loading.player
                            : 'card-back'
                    }
                />
                <rect width="180" height="40" x={0} y={330} fill="#7099b5" />
                {info.decks.player && (
                    <Deck x={6} y={330} state={info.decks.player} />
                )}
                {info.names.player && (
                    <NamePlate x={10} y={380} username={info.names.player} />
                )}
            </AnimatedLoadingDrawer>

            <AnimatedLoadingStatusBox state={loading} />

            <AnimatedLoadingStatusBar
                cx={512}
                cy={522}
                state={loading}
                sendUIEvent={(e) => console.error('todo - ', e)}
            />
            <AnimatedLoadingDrawer state={loading} x={651} y={155} side="right">
                <TV
                    x={12}
                    y={12}
                    card={
                        loading.type === 'cut-cards'
                            ? loading.opponent
                            : 'card-back'
                    }
                />

                <rect width="180" height="40" x={0} y={330} fill="#7099b5" />
                {info.decks.opponent && (
                    <Deck x={6} y={330} state={info.decks.opponent} />
                )}
                {info.names.opponent && (
                    <NamePlate x={10} y={380} username={info.names.opponent} />
                )}
            </AnimatedLoadingDrawer>
        </svg>
    )
}

type LoadingDrawerProps = {
    side: 'left' | 'right'
    state: LoadingState
} & SVGAttributes<SVGElement>

const AnimatedLoadingDrawer: FunctionComponent<LoadingDrawerProps> = (
    props
) => {
    const showing = props.state.type !== 'loading-done'

    const from =
        props.side === 'left' ? 'translateX(-100px)' : 'translateX(100px)'
    const to = 'translateX(0px)'

    const transitions = useTransition(showing, {
        from: { opacity: 0, transform: from },
        enter: { opacity: 1, transform: to },
        leave: { opacity: 0, transform: from },
    })

    return transitions(
        (style, item) =>
            item && (
                <animated.g style={style}>
                    <LoadingDrawer {...props} />
                </animated.g>
            )
    )
}

const LoadingDrawer: FunctionComponent<LoadingDrawerProps> = ({
    x,
    y,
    side,
    children,
}) => {
    const path = {
        right: {
            tv: 'M21.0062 14.6942 163.0062 57.1727C165.9697 58.0593 168 60.7858 168 63.8791L168 315C168 318.866 164.866 322 161 322L19 322C15.134 322 12 318.866 12 315L12 21.4005C12 17.5345 15.134 14.4005 19 14.4005C19.6794 14.4005 20.3552 14.4994 21.0062 14.6942Z',
            drawer: 'M8.8623 2.4459 174.8623 48.2594C177.8976 49.0971 180 51.8584 180 55.0072L180 424C180 427.866 176.866 431 173 431L7 431C3.134 431 0 427.866 0 424L0 9.1936C0 5.3276 3.134 2.1936 7 2.1936C7.6293 2.1936 8.2557 2.2784 8.8623 2.4459Z',
        },
        left: {
            tv: 'M158.9938 14.6942 16.9938 57.1727C14.0303 58.0593 12 60.7858 12 63.8791L12 315C12 318.866 15.134 322 19 322L161 322C164.866 322 168 318.866 168 315L168 21.4005C168 17.5345 164.866 14.4005 161 14.4005C160.3206 14.4005 159.6448 14.4994 158.9938 14.6942Z',
            drawer: 'M171.1377 2.4459 5.1377 48.2594C2.1024 49.0971 0 51.8584 0 55.0072L0 424C0 427.866 3.134 431 7 431L173 431C176.866 431 180 427.866 180 424L180 9.1936C180 5.3276 176.866 2.1936 173 2.1936C172.3707 2.1936 171.7443 2.2784 171.1377 2.4459Z',
        },
    }
    return (
        <svg x={x} y={y}>
            <path fill="url(#drawer-background)" d={path[side].drawer} />
            <path fill="url(#panel-background)" d={path[side].tv} />
            {children}
        </svg>
    )
}

type StatusBoxProps = {
    state: LoadingState
}

const AnimatedLoadingStatusBox: FunctionComponent<StatusBoxProps> = (props) => {
    const showing = props.state.type === 'cut-cards'

    const transitions = useTransition(showing, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    })

    return transitions(
        (style, item) =>
            item && (
                <animated.g style={style}>
                    <LoadingStatusBox {...props} />
                </animated.g>
            )
    )
}

const LoadingStatusBox: FunctionComponent<StatusBoxProps> = ({ state }) => {
    const textOptions = {
        fontFamily: FONT,
        fontStyle: 'italic',
        fontWeight: 'bold',
        color: '#A2F5F5',
        textAlign: 'center',
    } as CSSProperties

    var message = <></>

    if (state.type === 'cut-cards') {
        switch (state.win) {
            case 'none':
                message = (
                    <>
                        <p style={{ ...textOptions, fontSize: '24px' }}>
                            NO COLOR REVEALED
                        </p>
                        <p style={{ ...textOptions, fontSize: '12px' }}>
                            THIS GAME WILL BE DETERMINED BY POINTS ONLY.
                        </p>
                    </>
                )
                break
            case 'one':
                message = (
                    <>
                        <p style={{ ...textOptions, fontSize: '20px' }}>
                            ONE COLOR REVEALED
                        </p>
                        <p style={{ ...textOptions, fontSize: '10px' }}>
                            THIS GAME WILL BE DETERMINED BY POINTS.
                        </p>
                        <p style={{ ...textOptions, fontSize: '10px' }}>
                            THE PLAYER WITH THE MORE CARDS OF THIS COLOR EARNS
                            15 BONUS POINTS.
                        </p>
                    </>
                )
                break
            case 'two':
                message = (
                    <>
                        <p style={{ ...textOptions, fontSize: '18px' }}>
                            TWO COLORS REVEALED
                        </p>
                        <p style={{ ...textOptions, fontSize: '10px' }}>
                            IF A PLAYER HAS MORE CARDS OF BOTH COLORS, HE OR SHE
                            WINS AUTOMATICALLY.
                        </p>
                        <p style={{ ...textOptions, fontSize: '10px' }}>
                            IF NEITHER PLAYER HAS MORE OF BOTH COLORS, THE GAME
                            WILL BE DECIDED BY POINTS.
                        </p>
                    </>
                )
                break
        }
    }

    return (
        <>
            <svg x={406} y={268} width={212} height={212}>
                <rect
                    fill="url(#drawer-background)"
                    width="100%"
                    height="100%"
                    rx={8}
                    ry={8}
                />
                <rect
                    x={12}
                    y={12}
                    width={186}
                    height={186}
                    fill="url(#panel-background)"
                    rx={8}
                    ry={8}
                />
                <foreignObject x={30} y={12} width={156} height={186}>
                    {message}
                </foreignObject>
            </svg>
        </>
    )
}

const AnimatedLoadingStatusBar: FunctionComponent<LoadingStatusBarProps> = (
    props
) => {
    const loading = useGameSelector((s) => s.loading)
    const showing = loading.type !== 'loading-done'

    const transitions = useTransition(showing, {
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
    })

    return transitions(
        (style, item) =>
            item && (
                <animated.g style={style}>
                    <LoadingStatusBar {...props} />
                </animated.g>
            )
    )
}

type LoadingStatusBarProps = {
    cx?: number
    cy?: number
    state: LoadingState
    sendUIEvent: (e: UIEvent) => void
}

const LoadingStatusBar: FunctionComponent<LoadingStatusBarProps> = ({
    cx = 0,
    cy = 0,
    state,
    sendUIEvent,
}) => {
    const ready = useGameSelector((s) => s.playerIsReady)

    var message, width
    switch (state.type) {
        case 'loading-data':
            message = 'Loading Data'
            width = 170
            break
        case 'loading-done':
        case 'cut-cards':
            message = 'Selecting Cut Cards'
            width = 190
            break
    }

    if (ready) {
        message = 'Waiting for Opponent...'
        width = 210
    }

    return (
        <StatusBar
            cx={cx}
            cy={cy}
            width={width}
            action={'none'}
            message={message}
            sendUIEvent={sendUIEvent}
            timer={undefined}
        />
    )
}

export default Loading
