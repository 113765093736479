import { useSpring } from '@react-spring/core'
import { animated } from '@react-spring/web'
import { memo, SVGAttributes, useEffect } from 'react'
import { FunctionComponent } from 'react'
import { useDispatch } from 'react-redux'
import Card from '../common/Card'
import CardBack from '../common/CardBack'
import usePrevious from '../common/usePrevious'
import { gameActions } from '../state/gameReducer'
import { positionToCoordinates } from './coordinates'
import { PositionStatus, Position as PositionState } from './positions'

type PositionProps = {
    status: PositionStatus
    position: PositionState
    size: number
}

const AnimatedPosition: FunctionComponent<PositionProps> = memo((props) => {
    const previous = usePrevious(props.status)
    useEffect(() => {
        console.log(`${props.position} - ${previous?.type}`)
    }, [props.status])

    if (
        (previous?.type === 'card' && props.status.type === 'card-back') ||
        (previous?.type === 'card-back' && props.status.type === 'card')
    ) {
        return <Flip {...props} previous={previous} />
    }

    return <Position {...props} />
})

type FlipProps = {
    previous: PositionStatus
} & PositionProps
const Flip: FunctionComponent<FlipProps> = ({
    position,
    status,
    size,
    previous,
}) => {
    const { x } = positionToCoordinates(position)

    const { x: r } = useSpring({ from: { x: -180 }, x: 0, reset: true })
    const t = (x: number, p: number, r: number) =>
        `translateX(${x}px) perspective(${p}px) rotateY(${r}deg) translateX(${-x}px)`

    return (
        <>
            <animated.g
                style={{ transform: r.to((n) => t(x + size / 2, 1000, n)) }}
            >
                <Position position={position} status={status} size={size} />
            </animated.g>
        </>
    )
}

const Position: FunctionComponent<PositionProps> = ({
    status,
    position,
    size,
}) => {
    const coords = positionToCoordinates(position)
    const dispatch = useDispatch()

    switch (status.type) {
        case 'card':
            return (
                <Card
                    card={status.card}
                    modifications={status.modifications}
                    disabled={status.disabled}
                    width={size}
                    height={size}
                    x={coords.x}
                    y={coords.y}
                    onMouseEnter={(e) =>
                        dispatch(gameActions.setGameTV(status.card))
                    }
                />
            )
        case 'closed':
            return (
                <svg width={size} height={size} x={coords.x} y={coords.y}>
                    <circle
                        cx="50%"
                        cy="50%"
                        r={size / 2 - 4}
                        stroke="#B2D2E4"
                        strokeWidth={(4 * size) / 114}
                        fillOpacity={0}
                    />
                </svg>
            )
        case 'open':
            return (
                <EmptySlot
                    id={position}
                    x={coords.x}
                    y={coords.y}
                    size={size}
                />
            )
        case 'card-back':
            return (
                <CardBack
                    x={coords.x}
                    y={coords.y}
                    width={size}
                    height={size}
                />
            )
        default:
            return <></>
    }
}

type EmptySlotProps = {
    size: number
} & SVGAttributes<SVGElement>

const EmptySlot: FunctionComponent<EmptySlotProps> = ({ x, y, size, id }) => {
    return (
        <>
            <svg x={x} y={y} width={size} height={size}>
                <defs>
                    <circle
                        id={`${id}-circle`}
                        r={size / 2 - Math.max((4 * size) / 114, 2)}
                        cx={size / 2}
                        cy={size / 2}
                    />
                    <clipPath id={`${id}-circleClip`}>
                        <use xlinkHref={`#${id}-circle`} />
                    </clipPath>
                    <filter id={`${id}-inset-shadow`}>
                        {/* Shadow offset */}
                        <feOffset dx="0" dy="0" />

                        {/* Shadow blur */}
                        <feGaussianBlur
                            stdDeviation={size * 0.1153846154}
                            result="offset-blur"
                        />

                        {/* Invert drop shadow to make an inset shadow */}
                        <feComposite
                            operator="out"
                            in="SourceGraphic"
                            in2="offset-blur"
                            result="inverse"
                        />

                        {/* Cut color inside shadow */}
                        <feFlood
                            floodColor="black"
                            floodOpacity=".95"
                            result="color"
                        />
                        <feComposite
                            operator="in"
                            in="color"
                            in2="inverse"
                            result="shadow"
                        />

                        {/* Placing shadow over element */}
                        <feComposite
                            operator="over"
                            in="shadow"
                            in2="SourceGraphic"
                        />
                    </filter>
                </defs>
                <circle
                    fill="#B2D2E4"
                    r={size / 2}
                    cx={size / 2}
                    cy={size / 2}
                />
                {/* This filter thing causes lots of FPS issues */}
                {/* <use filter={`url(#${id}-inset-shadow)`} xlinkHref={`#${id}-circle`} fill="#7099B5" clipPath={`url(#${id}-circleClip)`} /> */}
            </svg>
        </>
    )
}

export { Position, AnimatedPosition }
