import { useState, useEffect } from 'react'

export function useApi<T>(
    apiCall: () => Promise<T>,
    callback?: (data: T) => void
): [boolean, T, Error | null] {
    const [data, setData] = useState<any>(null)
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<Error | null>(null)

    useEffect(() => {
        apiCall()
            .then((data: any) => {
                setData(data)
                setIsLoading(false)
                if (callback) {
                    callback(data)
                }
            })
            .catch((err: Error) => {
                setError(err)
                setIsLoading(false)
            })
    }, [apiCall, callback])

    return [isLoading, data, error]
}
