import { configureStore } from '@reduxjs/toolkit'
import gameReducer from './gameReducer'
import { initialize } from './initialize'
import userReducer from './userReducer'

const store = configureStore({
    reducer: {
        game: gameReducer,
        user: userReducer,
    },
})

export default store

initialize(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
