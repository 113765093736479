import {
    FunctionComponent,
    SVGAttributes,
    SVGProps,
    useEffect,
    useState,
} from 'react'
import styles from './Game.module.css'
import { useTimer } from 'react-timer-hook'
import {
    selectActionStatus,
    StatusAction,
    UIPhase,
    useGame,
    useGameSelector,
} from '../state/gameReducer'
import { UIEvent } from './useGameEngine'
import { Color } from './colors'
import { FONT } from './styling'
import { gradients } from '../common/Gradient'

type StatusBarProps = {
    cx?: number
    cy?: number
    message: string
    timer?: number
    width: number
    action: StatusAction
    sendUIEvent: (e: UIEvent) => void
}

type StatusButton = YesNoButton | ReadyButton
type YesNoButton = { type: 'yes-no'; handleEvent: (e: 'yes' | 'no') => void }
type ReadyButton = { type: 'ready'; handleEvent: (e: 'ready') => void }

const StatusBar: FunctionComponent<StatusBarProps> = ({
    cx = 0,
    cy = 0,
    width,
    message,
    timer,
    action,
    sendUIEvent,
}) => {
    const textOptions = {
        fontSize: '16px',
        fontFamily: FONT,
        // fontStyle: "italic",
        fontWeight: 'bold',
        fill: 'white',
        textAnchor: 'middle',

        dominantBaseline: 'middle',
    }

    var w = width,
        h = 36

    const Text = (
        <text x={w / 2} y={h / 2 + 1} width={w} height={h} {...textOptions}>
            {message}
        </text>
    )

    const timerSize = 100
    if (timer !== undefined) w += timerSize

    var Button = <></>
    if (
        action === 'ready' ||
        action === 'cancel' ||
        action === 'ready-cancel'
    ) {
        Button = (
            <>
                {(action === 'ready' || action === 'ready-cancel') && (
                    <StatusButton
                        x={8 + cx + w / 2}
                        y={cy - 14}
                        width={80}
                        direction="right"
                        sendUIEvent={() => sendUIEvent({ type: 'ready' })}
                    >
                        <text
                            x={0}
                            y={0}
                            fontSize="12px"
                            dominantBaseline="middle"
                            textAnchor="middle"
                            fontFamily={FONT}
                            fill="white"
                            fontWeight="bold"
                        >
                            Ready
                        </text>
                    </StatusButton>
                )}
                {(action === 'cancel' || action === 'ready-cancel') && (
                    <StatusButton
                        x={cx - 88 - w / 2}
                        y={cy - 14}
                        width={80}
                        direction="left"
                        sendUIEvent={() => sendUIEvent({ type: 'cancel' })}
                    >
                        <text
                            x={0}
                            y={0}
                            fontSize="12px"
                            dominantBaseline="middle"
                            textAnchor="middle"
                            fontFamily={FONT}
                            fill="white"
                            fontWeight="bold"
                        >
                            Cancel
                        </text>
                    </StatusButton>
                )}
            </>
        )
    } else if (action === 'yes-no') {
        Button = (
            <>
                <StatusButton
                    x={8 + cx + w / 2}
                    y={cy - 14}
                    width={60}
                    direction="right"
                    sendUIEvent={() => sendUIEvent({ type: 'yes' })}
                >
                    <text
                        x={0}
                        y={0}
                        fontSize="12px"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        fontFamily={FONT}
                        fill="white"
                        fontWeight="bold"
                    >
                        Yes
                    </text>
                </StatusButton>
                <StatusButton
                    x={cx - 68 - w / 2}
                    y={cy - 14}
                    width={60}
                    direction="left"
                    sendUIEvent={() => sendUIEvent({ type: 'no' })}
                >
                    <text
                        x={0}
                        y={0}
                        fontSize="12px"
                        dominantBaseline="middle"
                        textAnchor="middle"
                        fontFamily={FONT}
                        fill="white"
                        fontWeight="bold"
                    >
                        No
                    </text>
                </StatusButton>
            </>
        )
    } else if (action == 'colors') {
        const buttons = []
        const bw = 50
        const pad = 6

        const left = ['ORANGE', 'RED', 'SILVER', 'BLACK'] as Color[]
        const right = ['YELLOW', 'GREEN', 'BLUE', 'PURPLE'] as Color[]

        // left
        for (var i = 0; i < left.length; i++) {
            const color = left[i]
            const button = (
                <StatusButton
                    key={color}
                    x={cx - (bw + pad) * (i + 1) - w / 2}
                    y={cy - 14}
                    width={bw}
                    direction="left"
                    sendUIEvent={() => sendUIEvent({ type: 'color', color })}
                >
                    <rect
                        x={-13}
                        y={-8}
                        width={22}
                        height={16}
                        rx={3}
                        ry={3}
                        fill={`url(#${color})`}
                    />
                </StatusButton>
            )

            buttons.push(button)
        }

        for (var i = 0; i < right.length; i++) {
            const color = right[i]
            const button = (
                <StatusButton
                    key={color}
                    x={cx + bw * i + pad * (i + 1) + w / 2}
                    y={cy - 14}
                    width={bw}
                    direction="right"
                    sendUIEvent={() => sendUIEvent({ type: 'color', color })}
                >
                    <defs>{Object.values(gradients)}</defs>
                    <rect
                        x={-8}
                        y={-8}
                        width={22}
                        height={16}
                        rx={3}
                        ry={3}
                        fill={`url(#${color})`}
                    />
                </StatusButton>
            )

            buttons.push(button)
        }

        Button = <>{buttons}</>
    }
    return (
        <>
            <svg
                className={styles['shadow']}
                x={cx - w / 2}
                y={cy - h / 2}
                style={{ overflow: 'visible' }}
            >
                <rect
                    x={0}
                    y={0}
                    width={w}
                    height={h}
                    fill="#e8e8e8"
                    rx={h / 2}
                    ry={h / 2}
                />
                <rect
                    x={4}
                    y={4}
                    width={w - 8}
                    height={h - 8}
                    fill="url('#panel-background')"
                    rx={(h - 8) / 2}
                    ry={(h - 8) / 2}
                />
                {Text}
                {timer && (
                    <Timer
                        x={w - 30}
                        y={h / 2 + 1.5}
                        width={timerSize}
                        height={h}
                        timestamp={timer}
                    />
                )}
            </svg>
            {Button}
        </>
    )
}

type StatusButtonProps = {
    width: number
    x: number
    y: number
    direction: 'left' | 'right'
    sendUIEvent: () => void
} & SVGAttributes<SVGSVGElement>

const StatusButton: FunctionComponent<StatusButtonProps> = ({
    width,
    x,
    y,
    children,
    direction,
    sendUIEvent,
}) => {
    const [hover, setHover] = useState(false)

    const height = 28
    const stroke = 3

    return (
        <>
            <svg
                className={styles['shadow']}
                x={x}
                y={y}
                width={width}
                height={height}
                style={{ cursor: 'pointer' }}
                onClick={() => sendUIEvent()}
            >
                <defs>
                    <mask id="outer-clip">
                        <rect width="100%" height="100%" fill="white" />
                        <circle
                            cx={-6}
                            cy={height / 2}
                            r={height / 2}
                            fill="black"
                        />
                    </mask>

                    <mask id="inner-clip">
                        <rect width="100%" height="100%" fill="white" />
                        <circle
                            cx={-6 + stroke}
                            cy={height / 2}
                            r={height / 2}
                            fill="black"
                        />
                    </mask>
                </defs>
                <g
                    onMouseOver={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    transform={
                        direction === 'left'
                            ? `scale(-1, 1) translate(-${width}, 0)`
                            : ''
                    }
                >
                    <rect
                        x={-height}
                        y={0}
                        width={width + height}
                        height={height}
                        fill="#e8e8e8"
                        mask="url(#outer-clip)"
                        ry={height / 2}
                        rx={height / 2}
                    />
                    <rect
                        x={-height + stroke}
                        y={stroke}
                        width={width + height - stroke * 2}
                        height={height - stroke * 2}
                        fill="url(#panel-background)"
                        mask="url(#inner-clip)"
                        ry={(height - stroke * 2) / 2}
                        rx={(height - stroke * 2) / 2}
                    />
                </g>
                <g
                    onMouseOver={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                    transform={`translate(${width / 2}, ${height / 2})`}
                    fillOpacity={hover ? '100%' : '80%'}
                >
                    {children}
                </g>
            </svg>
        </>
    )
}

const Timer: FunctionComponent<
    { timestamp: number } & SVGProps<SVGTextElement>
> = ({ x, y, width, height, timestamp }) => {
    const { seconds, restart } = useTimer({ expiryTimestamp: timestamp })
    useEffect(() => {
        restart(timestamp)
    }, [timestamp])

    return (
        <text
            x={x}
            y={y}
            width={width}
            height={height}
            dominantBaseline="middle"
            fontFamily={FONT}
            fontWeight="bold"
            fontSize="12px"
            fill="white"
            fillOpacity="80%"
            textAnchor={'end'}
        >
            Time Left: {seconds}
        </text>
    )
}

export default StatusBar
