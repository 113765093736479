import { FunctionComponent, SVGAttributes } from 'react'
import { CSSProperties } from 'react'
import { Card as CardDTO } from '../apis/gtoons'
import Card from '../common/Card'
import CardBack from '../common/CardBack'
import { FONT } from './styling'

type TVProps = {
    card?: CardDTO | 'card-back'
} & SVGAttributes<SVGElement>

const TV: FunctionComponent<TVProps> = ({ card, x, y }) => {
    const textOptions: CSSProperties = {
        fontSize: '12px',
        fontFamily: FONT,
        fontStyle: 'italic',
        fontWeight: 'bold',
        color: '#A2F5F5',
        textAlign: 'center',
    }

    var display = <></>

    if (!card) return <></>
    else if (card === 'card-back')
        display = <CardBack x={21} y={40} width={114} height={114} />
    else
        display = (
            <>
                <Card card={card} width={114} height={114} x={21} y={40} />
                <foreignObject x="16" y="168" width="124" height="80">
                    <p style={{ ...textOptions, fontSize: '14px' }}>
                        {card.name}
                    </p>
                </foreignObject>
                <foreignObject x="16" y="224" width="124" height="60">
                    <p style={textOptions}>{card.description}</p>
                </foreignObject>
            </>
        )

    return (
        <svg width={156} height={286} x={x} y={y}>
            {display}
        </svg>
    )
}

export default TV
