export const EMPTY_DECKS = [
    { name: 'A', cards: [] },
    { name: 'B', cards: [] },
    { name: 'C', cards: [] },
    { name: 'D', cards: [] },
]

export function validDeck(deck: Deck) {
    return Array.isArray(deck.cards) && deck.cards.length <= 12
}

export function isComplete(deck: Deck) {
    return validDeck(deck) && deck.cards.length === 12
}

export type Deck = {
    cards: string[]
    name: string
}
