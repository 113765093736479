import {
    AllBoardPositions,
    AllHandPositions,
    BoardPosition,
    HandPosition,
    Position,
} from '../apis/gtoons'
import { AllDiscardPositions, AllPositions, DiscardPosition, UIPosition } from './positions'

type coordinate = { x: number; y: number }

export function positionToCoordinates(
    position: BoardPosition | HandPosition | DiscardPosition
): coordinate {
    switch (position) {
        case 'PLAYER_ONE':
            return { x: 234, y: 420 }
        case 'PLAYER_TWO':
            return { x: 378, y: 420 }
        case 'PLAYER_THREE':
            return { x: 522, y: 420 }
        case 'PLAYER_FOUR':
            return { x: 666, y: 420 }
        case 'PLAYER_FIVE':
            return { x: 304, y: 540 }
        case 'PLAYER_SIX':
            return { x: 451, y: 540 }
        case 'PLAYER_SEVEN':
            return { x: 598, y: 540 }

        case 'OPPONENT_ONE':
            return { x: 234, y: 206 }
        case 'OPPONENT_TWO':
            return { x: 378, y: 206 }
        case 'OPPONENT_THREE':
            return { x: 522, y: 206 }
        case 'OPPONENT_FOUR':
            return { x: 666, y: 206 }
        case 'OPPONENT_FIVE':
            return { x: 304, y: 86 }
        case 'OPPONENT_SIX':
            return { x: 451, y: 86 }
        case 'OPPONENT_SEVEN':
            return { x: 598, y: 86 }

        case 'HAND_ONE':
            return { x: 848, y: 412 }
        case 'HAND_TWO':
            return { x: 918, y: 412 }
        case 'HAND_THREE':
            return { x: 848, y: 482 }
        case 'HAND_FOUR':
            return { x: 918, y: 482 }
        case 'HAND_FIVE':
            return { x: 848, y: 552 }
        case 'HAND_SIX':
            return { x: 918, y: 552 }
        
        case 'DISCARD_ONE':
            return { x: 864, y: 660 }
        case 'DISCARD_TWO':
            return { x: 922, y: 678 }

        default:
            return { x: 0, y: 0 }
    }
}



function isBoardPosition(position: any): position is BoardPosition {
    return AllBoardPositions.includes(position)
}

function isHandPosition(position: any): position is HandPosition {
    return AllHandPositions.includes(position)
}

function isDiscardPosition(position: any): position is DiscardPosition {
    return AllDiscardPositions.includes(position)
}

function coordinateToPosition(
    coord: coordinate,
    size: number
): UIPosition | undefined {
    for (const position of AllPositions) {
        const positionCoords = positionToCoordinates(position)
        const [xDiff, yDiff] = [
            coord.x - positionCoords.x,
            coord.y - positionCoords.y,
        ]

        if (xDiff <= size && xDiff >= 0 && yDiff <= size && yDiff >= 0) {
            return position
        }
    }

    return undefined
}

export function coordinateToBoardPosition(
    coord: coordinate,
    size: number
): BoardPosition | undefined {
    const position = coordinateToPosition(coord, size)
    return isBoardPosition(position) ? position : undefined
}

export function coordinateToHandPosition(
    coord: coordinate,
    size: number
): HandPosition | undefined {
    const position = coordinateToPosition(coord, size)
    return isHandPosition(position) ? position : undefined
}

export function coordinateToDiscardPosition(coord: coordinate, size: number): DiscardPosition | undefined {
    const position = coordinateToPosition(coord, size)
    return isDiscardPosition(position) ? position : undefined
} 