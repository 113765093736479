import { FunctionComponent, MouseEvent, TouchEvent } from 'react'
import { RefObject, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Position } from '../apis/gtoons'
import { gameActions, selectHandPositions, useGame } from '../state/gameReducer'
import {
    CARD_SIZE,
    DISCARD_SIZE,
    GAME_HEIGHT,
    GAME_WIDTH,
    HAND_SIZE,
} from './constants'
import {
    coordinateToBoardPosition,
    coordinateToDiscardPosition,
    coordinateToHandPosition,
} from './coordinates'
import { UIPosition } from './positions'

const Cursor: FunctionComponent = ({ children }) => {
    const cursorContainer = useRef<SVGSVGElement>(null)
    const game = useGame()

    const { cursorEvent, svg } = useClickAndDrag(
        cursorContainer,
        game.held !== undefined
    )

    return (
        <svg
            width={GAME_WIDTH}
            height={GAME_HEIGHT}
            x={0}
            y={0}
            ref={svg}
            onMouseMove={cursorEvent}
            onMouseDown={cursorEvent}
            onMouseUp={cursorEvent}
            onMouseLeave={cursorEvent}
            onTouchStart={cursorEvent}
            onTouchEnd={cursorEvent}
            onTouchCancel={cursorEvent}
            onTouchMove={cursorEvent}
        >
            <rect
                x={0}
                y={0}
                width={GAME_WIDTH}
                height={GAME_HEIGHT}
                fillOpacity={0}
            />
            {children}
            <svg id="cursor-container" ref={cursorContainer} x={0} y={0} />
        </svg>
    )
}

const CURSOR_SIZE = 80
const cursor = (() => {
    const e = document.createElementNS('http://www.w3.org/2000/svg', 'rect')
    e.setAttribute('x', '0')
    e.setAttribute('y', '0')
    e.setAttribute('width', `${CURSOR_SIZE}`)
    e.setAttribute('height', `${CURSOR_SIZE}`)
    e.setAttribute('fill', 'white')
    e.setAttribute('fill-opacity', '0.8')
    return e
})()

type CursorEvent = TouchEvent<SVGElement> | MouseEvent<SVGElement>

const useSVGCoordinates = (
    callback: (e: CursorEvent, point: SVGPoint) => void
): [RefObject<SVGSVGElement>, (e: CursorEvent) => void] => {
    const svg = useRef<SVGSVGElement>(null)
    const [point, setPoint] = useState<SVGPoint>()

    useEffect(() => {
        if (svg.current) {
            setPoint(svg.current.createSVGPoint())
        }
    }, [])

    const toPointInSVGSpace = (e: CursorEvent) => {
        if (point) {
            if (
                window.TouchEvent &&
                e.nativeEvent instanceof window.TouchEvent
            ) {
                point.x = e.nativeEvent.changedTouches[0].clientX
                point.y = e.nativeEvent.changedTouches[0].clientY
            } else if (e.nativeEvent instanceof window.MouseEvent) {
                point.x = e.nativeEvent.clientX
                point.y = e.nativeEvent.clientY
            }

            const transformedPoint = point.matrixTransform(
                svg.current?.getScreenCTM()?.inverse()
            )
            callback(e, transformedPoint)
        }
    }

    return [svg, toPointInSVGSpace]
}

const useClickAndDrag = (
    container: RefObject<Element>,
    showCursor: boolean
) => {
    const [selectedPosition, setSelectedPosition] = useState<UIPosition | null>(
        null
    )
    const [holding, setHolding] = useState<boolean>(false)
    const [showingCursor, setShowingCursor] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (showCursor && !showingCursor) {
            setShowingCursor(true)
            container.current?.appendChild(cursor)
        } else if (!showCursor && showingCursor) {
            setShowingCursor(false)
            container.current?.removeChild(cursor)
        }
    }, [showingCursor, showCursor])

    const [svg, cursorEvent] = useSVGCoordinates((e, point) => {
        cursor.setAttribute('x', `${point.x - CURSOR_SIZE / 2}`)
        cursor.setAttribute('y', `${point.y - CURSOR_SIZE / 2}`)

        const boardPosition = coordinateToBoardPosition(point, CARD_SIZE)
        const handPosition = coordinateToHandPosition(point, HAND_SIZE)
        const discardPosition = coordinateToDiscardPosition(point, DISCARD_SIZE)

        if (e.type === 'mousedown' || e.type === 'touchstart') {
            setSelectedPosition(
                boardPosition || handPosition || discardPosition || null
            )
        } else if (e.type === 'mouseup' || e.type === 'touchend') {
            var dropPosition = boardPosition || handPosition || discardPosition
            dispatch(gameActions.drop(dropPosition))
            setSelectedPosition(null)
            setHolding(false)
        } else if (e.type === 'mousemove' || e.type === 'touchmove') {
            if (selectedPosition && !holding) {
                dispatch(gameActions.hold(selectedPosition))
                setHolding(true)
            }
        } else if (e.type === 'mouseleave' || e.type === 'touchcancel') {
            setSelectedPosition(null)
            setHolding(false)
            dispatch(gameActions.drop())
        }
    })

    return { svg, cursorEvent }
}

export default Cursor
