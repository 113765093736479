import { FunctionComponent, SVGAttributes } from 'react'

const CardBack: FunctionComponent<SVGAttributes<SVGElement>> = ({
    x,
    y,
    width,
    height,
}) => {
    return (
        <svg x={x} y={y} width={width} height={height} viewBox="0 0 298 298">
            <g id="card-back" fillRule="evenodd">
                <circle id="Oval" fill="#E3EEF4" cx="149" cy="149" r="149" />
                <circle
                    id="Oval"
                    fill="#B2D2E4"
                    cx="149"
                    cy="149"
                    r="137.784946"
                />
                <circle id="Oval" fill="#83A2BB" cx="149" cy="149" r="126" />
                <circle id="Oval" fill="#B2D2E4" cx="149" cy="149" r="118" />
                <mask id="mask-2" fill="white">
                    <circle id="Oval" cx="149" cy="149" r="118" />
                </mask>
                <use id="Oval" fill="#B2D2E4" xlinkHref="#path-1" />
                <polygon
                    id="Path"
                    fill="#83A2BB"
                    fillRule="nonzero"
                    mask="url(#mask-2)"
                    points="159.619 58.145 149 -14.4573054 138.38 58.145 111.304151 -10.0512926 117.714 63.043 75.6404953 2.92921661 98.734 72.575 43.9316694 23.7844395 82.464 86.227 17.8871043 51.3900657 69.782 103.263 -1.08913059 84.2578687 61.369 122.765 -11.9740216 120.615937 57.681 143.681 -14.1807615 158.504197 58.917 164.883 -7.59038427 195.880084 65.007 185.231 7.4418211 230.728653 75.626 203.624 30.1054645 261.171209 90.202 219.072 59.1787432 285.566586 107.946 230.743 93.094309 302.599624 127.904 238.007 130.023765 311.352066 149 240.471 167.976235 311.352066 170.095 238.007 204.905691 302.599624 190.053 230.743 238.821257 285.566586 207.797 219.072 267.894535 261.171209 222.373 203.624 290.558179 230.728653 232.992 185.231 305.590384 195.880084 239.082 164.883 312.180761 158.504197 240.318 143.681 309.974022 120.615937 236.63 122.765 299.089131 84.2578687 228.217 103.263 280.112896 51.3900657 215.535 86.227 254.068331 23.7844395 199.265 72.575 222.359505 2.92921661 180.285 63.043 186.695849 -10.0512926"
                />
                <polygon
                    id="Path"
                    fill="#B2D2E4"
                    fillRule="nonzero"
                    mask="url(#mask-2)"
                    points="149 27 156.926015 81.1886112 177.135136 30.2885258 172.35075 84.8443407 203.7535 39.9768179 186.516641 91.9587184 227.420088 55.5425779 198.66 102.148206 246.85903 76.1466518 208.126175 114.863487 261.022365 100.678269 214.404843 129.419075 269.146546 127.814922 217.157519 145.030277 270.793595 156.093669 216.235806 160.855487 265.874721 183.989994 211.689393 176.041564 254.655099 210 203.763378 189.769824 237.739584 232.72148 192.885056 201.300173 216.040095 250.929513 179.640878 210.011005 190.726457 263.6425 164.744843 215.432718 163.163336 270.17508 149 217.273027 134.836664 270.17508 133.255157 215.432718 107.273543 263.6425 118.359122 210.011005 81.9599047 250.929513 105.114944 201.300173 60.2604157 232.72148 94.2366219 189.769824 43.3449007 210 86.3106073 176.041564 32.1252795 183.989994 81.7641941 160.855487 27.2064047 156.093669 80.8424805 145.030277 28.8534541 127.814922 83.5951565 129.419075 36.977635 100.678269 89.8738245 114.863487 51.1409705 76.1466518 99.34 102.148206 70.5799116 55.5425779 111.483359 91.9587184 94.2465 39.9768179 125.64925 84.8443407 120.864864 30.2885258 141.073985 81.1886112"
                />
                <path
                    d="M173.450993,180.148536 C181.809215,151.469833 185.686921,136.828763 185.084111,136.225328 C184.703963,135.84478 173.162434,138.93804 150.459524,145.505109 C149.783715,147.711159 149.232238,149.958727 148.805094,152.247812 C148.395791,154.441282 148.099058,156.727964 147.914895,159.107858 C150.446367,158.546221 152.879696,157.821024 155.214882,156.932266 C156.608491,156.401874 157.954725,156.16832 159.253583,156.231604 C160.0637,157.922659 160.0637,160.36564 159.253583,163.560547 C157.723438,169.595087 156.895531,170.34031 149.170445,172.636794 C138.997107,175.661072 135.98873,175.757089 133.37528,173.140934 C128.309483,168.069907 133.616561,146.566811 142.311756,136.932326 C147.367764,131.33015 150.353487,129.895483 166.886992,125.123741 L185.706998,119.692096 L187.64707,112.430891 C188.35843,109.768442 189.00512,106.86125 189.587141,103.709315 C165.899255,108.548986 150.722732,112.600988 144.05757,115.865321 C126.581502,124.424387 113.623603,142.631716 109.634357,164.23382 C107.501206,175.785011 108.709725,184.076441 113.178554,188.549874 C117.947338,193.323582 130.113119,192.711012 152.823772,186.553634 C159.900843,184.634872 166.776583,182.499839 173.450993,180.148536 Z"
                    id="path28-path"
                    fillOpacity="0.5"
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    mask="url(#mask-2)"
                />
            </g>
        </svg>
    )
}

export default CardBack
