import { FunctionComponent } from 'react'
import logo from '../resources/images/logo.svg'

type LogoProps = {
    type?: keyof typeof styles
}

const Logo: FunctionComponent<LogoProps> = ({ type = 'large' }) => (
    <>
        <img alt="gTOONS Remastered" src={logo} style={styles[type]} />
    </>
)

const styles = {
    small: {
        height: '120px',
        padding: '8px',
    },
    large: {
        display: 'block',
        margin: '96px auto 80px auto',
        height: '320px',
    },
}

export default Logo
